import ipcRenderer from './ipcrenderer'

// 删除文件
export const delFile = (path) => {
  return new Promise((resolve, reject) => {
    ipcRenderer.send('delFile', path)
    ipcRenderer.once('delFileSuccess', (event, arg) => {
      // 回传数据
      resolve(arg)
    })
  })
}
// 检测文件是否存在
export const checkFilePath = (path) => {
  return new Promise((resolve, reject) => {
    ipcRenderer.send('checkLocalFile', path)
    ipcRenderer.once('checkLocalFileRes', (event, arg) => {
      // 回传数据
      resolve(arg)
    })
  })
}

// 主动停止下载
export const initiativeStopDownloadFn = () => {
  ipcRenderer.send('initiativeStopDownload')
}

// 接收主动停止下载成功
export const receiveStopDownloadFn = (cb) => {
  ipcRenderer.on('resetDownload', (event) => {
    cb()
  })
}
// 开始下载
export const downloadStart = cb => {
  ipcRenderer.on('downloadStart', (event) => {
    cb()
  })
}
// 解除只读
export const unReadeOnlyFileFn = path => {
  ipcRenderer.send('unReadeOnlyFile', path)
}
// 获取保存路径
export const getSavePathFn = (fileName, cb) => {
  ipcRenderer.send('getSavePath', fileName)
  ipcRenderer.once('savePath', (res) => {
    cb(res)
  })
}
// 打开 3D 文件窗口
export const open3DFn = (res) => {
  ipcRenderer.send('open3D', res)
}

// 通知主窗口下载文件
export const sendMainWindowDownloadFn = (data, call) => {
  ipcRenderer.send('sendMainWindowDownload', data)
  ipcRenderer.on('receiveMainWindowDownload', (e, res) => {
    call(res)
  })
}

// 主窗口接收下载信息
export const receiveMainWindowDownloadFn = (call) => {
  ipcRenderer.on('mainSendMainWindowDownload', (e, data) => {
    call(data)
  })
}

// 主窗口发送下载结果
export const sendMainWindowDownloadCompleteFn = (data) => {
  ipcRenderer.send('sendMainWindowDownloadComplete', data)
}

// 下载图片
export const sendMainWindowDownloadImgFn = (data, name, call) => {
  ipcRenderer.send('imgSave', { data, name })
  ipcRenderer.once('imgSaveSuccess', (e, res) => {
    call && call(res)
  })
}

// 下载pc端小图片
export const downloadSmallFileFn = (params, call) => {
  ipcRenderer.send('downloadSmallFile', params)
  ipcRenderer.once('downloadSmallFileSuccess', (e, res) => {
    call(res)
  })
}
