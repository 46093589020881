
const path = {
  state: {
    currentId: 0,
    openName: [1000],
    activeName: 1001
  },
  mutations: {
    setCurrentId (state, id) {
      state.currentId = id
    },
    setOpenName (state, name) {
      state.openName = name
    },
    setActiveName (state, name) {
      state.activeName = name
    }
  },
  actions: {
    updateCurrentId ({ commit }, val) {
      commit('setCurrentId', val)
    },
    updateOpenName ({ commit }, val) {
      commit('setOpenName', val)
    },
    updateActiveName ({ commit }, val) {
      commit('setActiveName', val)
    }
  }
}

export default path
