import mqtt from 'mqtt/dist/mqtt'
import { getToken } from '@/storage/viewStorage'
import { validateToken } from '@/api/login'
class Mqtt {
  constructor (wsUrl) {
    // 区分web 和pc / 时间戳+随机数(100-999)
    let clientId = 'web_' + Date.now() + '_' + (Math.ceil(Math.random() * 888) + 100)
    if (navigator.userAgent.indexOf('Electron') > -1) {
      clientId = 'pc_' + Date.now() + '_' + (Math.ceil(Math.random() * 888) + 100)
    }
    const maxRetryTimes = 3
    const opts = {
      connectTimeOut: 4000, // 链接超时4秒
      clientId: clientId,
      clean: true,
      username: getToken(), // 用户名为token
      password: '',
      reconnectPeriod: 3000, // 3秒；连一次,
      keepAliveInterval: 30, // 30秒一个心跳
      reconnect: true, // 启动重连机制
      reconnectInterval: 10 // 这个参数废弃?
    }

    this.retryTimes = 0
    const client = mqtt.connect(wsUrl, opts)
    this.client = client
    this.wsUrl = wsUrl
    this.token = getToken()
    this.subscribeList = []
    this.connect = false
    client.on('connect', () => {
      console.log('已连接')
      this.connect = true
    })
    client.on('reconnect', (e) => {
      console.log('ws重复连接中。。。')
      this.connect = false
      this.retryTimes = this.retryTimes + 1
      if (this.retryTimes > maxRetryTimes) {
        console.log('已达到重试最大次数,mqtt 客户端关闭')
        this.close()
        this.retryTimes = 0
      }
    })
    client.on('error', (err) => {
      console.error('连接失败:' + err)
      validateToken(this.token).then(() => {
        if (this.retryTimes > maxRetryTimes) {
          console.log('已达到重试最大次数,mqtt 客户端关闭')
          this.close()
          this.retryTimes = 0
        }
      }).catch(err => {
        console.log('token 失效,mqtt 客户端关闭', err)
        this.close()
        this.retryTimes = 0
      })
    })
  }

  receiver (url, fn) {
    if (this.connect) {
      this.subscribe(url)
    } else {
      this.client.on('connect', () => {
        this.connect = true
        this.subscribe(url)
      })
    }
    this.client.on('message', (topic, message) => {
      if (topic === url) {
        const _msg = message.toString()
        const msgJson = JSON.parse(_msg)
        fn(msgJson)
      }
    })
  }

  subscribe (url) {
    // 排除已经订阅
    if (this.subscribeList.findIndex(val => val === url) > -1) {
      return
    }
    this.client.subscribe(url, (err) => {
      if (err) {
        console.log('ws订阅失败')
        console.log('wx', err)
        return
      }
      this.subscribeList.push(url)
      console.log(`ws已订阅:${url}`)
    })
  }

  unSubscribe (url) {
    this.client.unsubscribe(url)
  }

  close () {
    console.log('close 1')
    if (this.client) {
      console.log('close 2')
      this.client.end()
      this.client = null
      this.connect = false
      this.token = null
    }
  }
}

export default Mqtt
