<template>
  <div class="task-main">
    <div class="task-body">
      <div class="body-top">
        <div class="header-top">
          <div>新手任务</div>
          <div class="close" @click="close">
            <Icon size="34" type="ios-close"/>
          </div>
        </div>
        <div class="header-content">
          <div class="header-img"><img alt="" src="~@/assets/home/new-task-img.png"></div>
          <div class="header-text">欢迎使用云科学平台，为了帮助您更好的体验产品，我们特意为您安排了指引任务，完成任务获得积分即可兑换一个月会员！</div>
        </div>
      </div>
      <div class="content">
        <div v-for="(item, index) in list" :key="index" class="task-item">
          <div class="item-content">
            <div class="top">
              <div class="index">{{ index + 1 }}</div>
              <div class="title">{{ item.taskName }}</div>
            </div>
            <div class="center">{{ item.content }}</div>
            <div class="b-p">
              <div class="left"><img alt="" src="~@/assets/home/new-task-point.png"></div>
              <div class="point">+{{ item.point }}</div>
            </div>
          </div>
          <div class="bottom">
            <div v-if="item.ifUserComplete === 1" class="finish">已领取</div>
            <div v-else-if="item.ifUserComplete === 0" class="btn" @click="addpoint(item)">领取</div>
            <div v-else-if="item.ifUserComplete === -1">
              <div v-if="item.taskType === '5' && certInfo.auditFlag === '0'" class="btn">认证中</div>
              <div v-else-if="item.taskType === '5' && certInfo.auditFlag === '1'" class="button" @click="certify">未认证</div>
              <div v-else-if="item.taskType === '5'" class="button" @click="certify">去认证</div>
              <div v-else class="btn no-btn">待完成</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer"></span>
    </div>

    <Modal v-model="certifyVisible" :z-index="1120" align="center" title="教师认证" width="540">
      <Form ref="teacher" :label-width="120" :model="teacher" :rules="rules" label-colon style="max-width: 480px">
        <FormItem label="教师姓名" prop="teacherName">
          <Input v-model="teacher.teacherName" placeholder="请输入"></Input>
        </FormItem>
        <FormItem label="教师资格证号" prop="teacherCertNo">
          <Input v-model="teacher.teacherCertNo" placeholder="请输入"></Input>
        </FormItem>
        <FormItem label="上传证件" prop="teacherCertUrl">
          <ctyunimage ref="imageUpload" :limit="1" :bucketType="3" :on-success="coverUploadSuccess"></ctyunimage>
          <div class="text">说明：教师资格证明图片仅限教师身份审核用，上传的图片大小须小于5M，格式必须为.jpg或.png。
            <label class="icon" @click="showPicture = true">查看示意图</label>
          </div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" @click="onSave">确定</Button>
        <Button type="" @click="onCancel">取消</Button>
      </div>
    </Modal>

    <Modal v-model="showPicture" :z-index="1200" align="center" width="540">
      <div>
        <img src="~@/assets/teacherCourse/certfiti_l.jpg" style="width: 420px;min-height: 300px">
      </div>
      <div slot="footer"></div>
    </Modal>
  </div>
</template>

<script>
import { obtainUcTaskPoints, queryTaskList } from '@/api/system'
import ctyunimage from '@/common/components/biz/ctyunimage'
import { teacherCertify } from '@/api/teacher'

export default {
  name: 'new-task',
  components: {
    ctyunimage
  },
  data () {
    const nameValidator = (rule, value, callback) => {
      if (!/[\u4e00-\u9fa5]+/.test(value)) {
        callback(new Error('姓名必须填写汉字!'))
      }
      callback()
    }
    const certValidator = (rule, value, callback) => {
      if (!/^\d{15,17}$/.test(value)) {
        callback(new Error('仅支持输入17位或15位教师资格证明编号!'))
      }
      callback()
    }
    return {
      list: [],
      certInfo: {},
      certifyVisible: false,
      resultList: [],
      teacher: {
        teacherName: '',
        teacherCertNo: '',
        teacherCertUrl: ''
      },
      teacherCertOutUrl: '',
      rules: {
        teacherName: [
          { required: true, message: '请填写姓名', trigger: 'blur' },
          { validator: nameValidator, trigger: 'blur' }
        ],
        teacherCertNo: [
          { required: true, message: '请填写教师资格证编号', trigger: 'blur' },
          { validator: certValidator, trigger: 'blur' }
        ],
        teacherCertUrl: [{ required: true, message: '请上传教师资格证', trigger: 'change' }]
      },
      showPicture: false
    }
  },
  created () {
    this.getList()
    this.queryCertInfo()
  },
  methods: {
    queryCertInfo () {
      // 用户认证详情
      this.$store.dispatch('userInfo/getUserCertInfo').then(res => {
        this.certInfo = Object.assign({}, res)
      })
    },
    addpoint (val) {
      if (val.ifUserComplete !== 0) {
        return false
      }
      obtainUcTaskPoints({ taskType: val.taskType }).then((res) => {
        this.$Message.success('领取成功')
        const index = this.list.findIndex(item => {
          return item.taskType === val.taskType
        })
        this.list[index].ifUserComplete = 1
      })
    },
    getList () {
      queryTaskList().then((res) => {
        this.list = res.data.data
      })
    },
    certify () {
      // 审核不通过的时候
      if (this.certInfo && this.certInfo.auditFlag === '1') {
        this.teacher.teacherName = this.certInfo.teacherName
        this.teacher.teacherCertNo = this.certInfo.teacherCertNo
        this.teacher.teacherCertUrl = this.certInfo.teacherCertUrl
        this.$refs.imageUpload.resultList.push(this.certInfo.teacherCertOutUrl)
      }
      this.certifyVisible = true
    },
    coverUploadSuccess (res) {
      this.teacher.teacherCertUrl = res.key
    },
    onSave () {
      // validate
      this.$refs.teacher.validate(valid => {
        if (valid) {
          teacherCertify(this.teacher).then(res => {
            if (res.data.code === 200) {
              this.$Message.success('认证申请已提交!')
              this.onCancel()
              this.queryCertInfo()
            }
          })
        }
      })
    },
    onCancel () {
      this.teacher = Object.assign({}, {
        teacherName: '',
        teacherCertNo: '',
        teacherCertUrl: ''
      })
      this.certifyVisible = false
    },
    close () {
      this.$store.commit('baseCourse/setNewTask', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.text{
  font-size: 13px;
  color: #999FB5;
  line-height: 20px;
  text-align: left;
  .icon{
    display: inline-block;
    height: 22px;
    color: #0088fe;
    line-height: 22px;
    cursor: pointer;
  }
}
.task-main {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .task-body {
    width: 828px;
    height: 572px;
    background: #FFFFFF;
    border-radius: 10px;

    // opacity: 1 !important;
    .body-top {
      width: 100%;
      height: 246px;
      padding: 0 24px;
      background-image: url('~@/assets/home/new-task-bg.png');
      background-repeat: no-repeat;
      background-size: 100%;
      font-size: 18px;
      color: #6B4A34;
      border-radius: 8px 8px 0 0;
      line-height: 50px;
      margin-bottom: 20px;

      .header-top {
        width: 100%;
        position: relative;

        .close {
          position: absolute;
          top: 0;
          right: -15px;
          cursor: pointer;
        }
      }
    }
  }
}

// ::v-deep {
//   .ivu-modal-footer {
//     border-top: none;
//     padding: 0;
//   }
//   .ivu-modal .ivu-modal-content {
//     width: 828px;
//     height: 572px;
//   }
//   .ivu-modal-header {
//     padding: 0 24px;
//     height: 246px;
//     background-image: url('~@/assets/home/new-task-bg.png');
//     background-repeat: no-repeat;
//     background-size: 100%;
//     font-size: 18px;
//     color: #6B4A34;
//     border-radius: 8px 8px 0 0;
//     line-height: 50px;
//     // padding-left: 24px;
//   }
// }
.header-content {
  width: 100%;
  text-align: center;

  .header-img {
    width: 94px;
    height: 94px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .header-text {
    width: 487px;
    font-size: 16px;
    line-height: 30px;
    margin: 0 auto;
    margin-top: 15px;
  }
}

.content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 24px;

  .task-item {
    .item-content {
      width: 180px;
      height: 214px;
      border: 1px solid #E4E4E4;
      border-radius: 5px;
      padding: 12px 14px 18px 14px;

      .top {
        width: 100%;
        display: flex;
        align-items: center;

        .index {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #FFEDD6;
          font-size: 20px;
          color: #FA6400;
          line-height: 24px;
          text-align: center;
          margin-right: 8px;
        }

        .title {
          flex: 1;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .center {
        width: 100%;
        height: 120px;
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        margin: 12px 0 8px;
        overflow: hidden;
      }

      .b-p {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        .left {
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .point {
          font-size: 16px;
          color: #FA6400;
          margin-left: 5px;
        }
      }
    }

    .bottom {
      width: 100%;
      text-align: center;
      padding-bottom: 22px;
      margin-top: 21px;

      .finish {
        width: 96px;
        height: 34px;
        border: 1px solid #FA6400;
        border-radius: 24px;
        text-align: center;
        line-height: 34px;
        font-size: 16px;
        color: #FA6400;
        margin: 0 auto;
      }

      .btn {
        width: 96px;
        height: 36px;
        line-height: 36px;
        background: #FA6400;
        border-radius: 24px;
        text-align: center;
        // line-height: 36px;
        color: #FFFFFF;
        font-size: 16px;
        margin: 0 auto;
        cursor: pointer;
      }

      .button {
        width: 96px;
        height: 36px;
        background: #0066FF;
        border-radius: 24px;
        text-align: center;
        line-height: 36px;
        color: #FFFFFF;
        font-size: 16px;
        margin: 0 auto;
        cursor: pointer;
      }

      .no-btn {
        background: #cccccc;
        border: 1px solid #cccccc;
        cursor: no-drop;
      }
    }
  }
}
</style>
