import ipcRenderer from './ipcrenderer'
// import { throttlePlus } from '@c/libs/utils'

// tips：electron版本过低此版本不包含同步方法
// on为异步逻辑，但渲染进程需要同步获知下载路径，这里通过自定义事件触发回调完成。
// 事件bus中的（js）同步逻辑无法影响到其他实例，所以js同步逻辑无法处理

// 获取资源下载目录
export const getAppPath = callback => {
  ipcRenderer.invoke('mainMessagePath').then(arg => callback(arg))
}

// 本地资源列表确认
export const resourceConfirmation = () => {
  ipcRenderer.send('resourceConfirmation')
}

// 获取主进程中的下载任务
export const getDownloadQueue = callback => {
  // 发送事件通知
  ipcRenderer.send('pageResourcesStatus')
  ipcRenderer.once('donwloadQueue', (event, arg) => {
    // 回传数据
    callback(arg)
  })
}

// 获取主进程中的下载异常的任务
export const getErrorDownloadQueue = callback => {
  // 发送事件通知
  ipcRenderer.send('getErrorDownloadFiles')
  ipcRenderer.on('errorDownlaod', (event, arg) => {
    // 回传数据
    callback(arg)
    // console.log('getErrorDownloadQueue', arg)
  })
}

// 下载开始或下载完成收到的通知
export const downloadState = callback => {
  ipcRenderer.on('downloadState', callback)
}

// 通知下载
export const downloadFile = arg => {
  ipcRenderer.send('downloadFile', arg)
}

// 监听下载进度事件
export const getDownloadProgress = callback => {
  // 节流可能导致定时器工作期间最后一次状态不被返回。在这里表现为进度为100时可能不会执行回调
  // ipcRenderer.on('progressing', throttlePlus(callback))
  ipcRenderer.on('progressing', callback)
}

// 监听下载完成事件
export const getDownloadCompleted = callback => {
  ipcRenderer.on('completed', callback)
}

// 移除下载进度事件
export const removeProcessListener = callback => {
  ipcRenderer.removeListener('progressing', callback)
}

// 移除下载完成事件
export const removeCompleteListener = callback => {
  ipcRenderer.removeListener('completed', callback)
}

// 通知mainWindow进程刷新下载
export const downloadStateRefresh = () => {
  ipcRenderer.send('downloadStateRefresh')
}

// 通知mainWindow进程当前下载的状态
export const downloadProcessRefresh = res => {
  ipcRenderer.send('downloadProcessRefresh', res)
}

// 通知mainWindow进程当前下载已完成
export const downloadFinishRefresh = res => {
  ipcRenderer.send('downloadFinishRefresh', res)
}

// 打开点名工具
export const ipcOpenRollCall = res => {
  ipcRenderer.send('OpenRollCall')
}

// 打开计时器
export const ipcOpenCountDown = res => {
  ipcRenderer.send('OpenCountDown')
}

// 打开课程记录窗口
export const ipcOpenClassRecordWindow = res => {
  ipcRenderer.send('openClassRecordWindow')
}
// 关闭课程记录窗口
export const ipcCloseClassRecordWindow = res => {
  ipcRenderer.send('closeClassRecordWindow')
}
// 最小化课程记录窗口
export const ipcMiniClassRecordWindow = res => {
  ipcRenderer.send('miniClassRecordWindow')
}

// 打开课程考勤窗口
export const ipcOpenClassAttendWindow = res => {
  ipcRenderer.send('openClassAttendWindow')
}
// 关闭课程考勤窗口
export const ipcCloseClassAttendWindow = res => {
  ipcRenderer.send('closeClassAttendWindow')
}
// 最小化课程考勤窗口
export const ipcMiniClassAttendWindow = res => {
  ipcRenderer.send('miniClassAttendWindow')
}

// 打开上课面板
export const ipcShowClassWindow = scale => {
  ipcRenderer.send('showClassWindow', { scale })
}

// 小化上课面板
export const ipcHideClassWindow = scale => {
  ipcRenderer.send('hideClassWindow', { scale })
}

// 打开录课窗口
export const ipcCaptureLesson = callback => {
  ipcRenderer.send('captureLesson', 2)
  console.log('ipcCaptureLesson->captureLesson')
}
// 关闭录课窗口
export const closeipcCaptureLesson = callback => {
  console.log('closecaptureLesson->closecaptureLesson')
  ipcRenderer.send('closecameraLesson')
  ipcRenderer.send('closecaptureLesson')
}

// 打开摄像头窗口
export const ipcCameraWindow = callback => {
  ipcRenderer.send('cameraLesson')
}
// 关闭摄像头窗口
export const closeipcCameraWindows = callback => {
  ipcRenderer.send('closecameraLesson')
}

// 下课
export const ipcOffClass = callback => {
  ipcRenderer.send('OffClass', {
    status: false,
    type: 'close',
    flag: 'bridge'
  })
  ipcRenderer.on('off-class-reply', callback)
  // ipcShowMain()
}

// 返回桌面
export const ipcHideClass = callback => {
  ipcRenderer.send('hideClass')
}

// 上课
export const ipcOnClass = callback => {
  ipcRenderer.send('hiddenApp')
  ipcRenderer.send('OnClass')
}

// 显示主窗口
export const ipcShowMain = res => {
  console.log(188)
  ipcRenderer.send('showMain')
}

// 监听主进程网络状态回复
export const ipcNetworkMsgReply = callback => {
  ipcRenderer.on('networkMsgReply', (event, data) => {
    callback(data)
  })
}

// 打开浏览器
export const openBrowseFn = res => {
  ipcRenderer.send('openBrowse', res)
}

// 更新当前用户账户场景信息
export const updateAccountContext = (data) => {
  ipcRenderer.send('updateAccountContext', data)
}

// 获取版本号
export const getAppVersionFn = () => {
  return ipcRenderer.invoke('getAppVersion')
}

// 获取分辨率比例
export const getScalenFn = () => {
  return ipcRenderer.invoke('getScale')
}

// 获取machineIden
export const getMachineIdenUseIpc = () => {
  return ipcRenderer.invoke('getMachineIden')
}

// 模拟鼠标
export const keyboardSimulate = (key) => {
  ipcRenderer.send('keyboardSimulate', key)
}

// 打开文件夹
export const ipcOpenDirectory = (subDir) => {
  ipcRenderer.send('openDirectory', subDir)
}
