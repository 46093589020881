import axios from '@/common/request/request'
import config from '@/config'
import { getToken, getUserId } from '@/storage/viewStorage'
// import { isArray } from 'lodash'
// 用户未读数量
export const getMsgNum = params => {
  return axios.request({
    method: 'get',
    // url: 'system/receive/msgnum',
    url: '/message/ucUserMessage/pc/getUnReadNum',
    params: params
  })
}
// 用户未读数量
export const getNewMsgNum = () => {
  // let params = {}
  //   params.readFlag = '0'
  //   params.userId = store.state.user.id
  return getMsgNum()
}
// 获得我的消息列表
export const getMyMsg = params => {
  params.userId = getUserId()
  return axios.request({
    method: 'get',
    url: 'message/ucUserMessage/pc/list',
    params: params
  })
}

// 我的消息列表置为已读
export const readIt = msgId => {
  let id = msgId
  if (Array.isArray(msgId)) {
    id = msgId.join(',')
  }
  return axios.request({
    method: 'put',
    url: `/message/ucUserMessage/update2Read/${id}`
  })
}

// 删除消息
export const delInfoApi = msgId => {
  let id = msgId
  if (Array.isArray(msgId)) {
    id = msgId.join(',')
  }
  return axios.request({
    method: 'delete',
    url: `/message/ucUserMessage/${id}`
  })
}

// 获得我的消息列表
export const pushUrl = params => {
  const userId = getUserId()
  const token = getToken()
  return config.coomUrl.pushUrl + `?userId=${userId}&token=${token}`
}

// 获取详细详情
export const getInfoDetailApi = id => {
  return axios.request({
    method: 'get',
    url: `/message/ucUserMessage/${id}`
  })
}

// 分类获取未读消息分类数量
export const getNodReadTypeApi = () => {
  return axios.request({
    method: 'get',
    url: '/message/ucUserMessage/pc/getUnReadNumByCategory'
  })
}

// 次数埋点
export const recordNumberApi = data => {
  return axios.request({
    method: 'post',
    url: '/res/dailyFrequency/count',
    data
  })
}

// 时长埋点
export const recordTimeApi = data => {
  return axios.request({
    method: 'post',
    url: '/res/dailyDuration/statistics',
    data
  })
}

// 读取系统公告
export const getReadPlatformMsgApi = (msgId) => {
  return axios.request({
    method: 'get',
    url: `/message/message/readCnt/${msgId}`
  })
}
