<template>
  <div class="task-main">
    <div class="task-body">
      <div class="body-top">
        <div class="header-top">
          <div>积分获取指引</div>
          <div @click="close" class="close"><Icon type="ios-close" size="34" /></div>
        </div>
      </div>
      <div class="content">
        <div class="task-item" v-for="item in list" :key="item.id">
          <div class="item-left"><img :src="item.img" alt=""></div>
          <div class="item-center">{{ item.title }}</div>
          <div class="item-right">
            <div class="point-img"><img src="~@/assets/home/new-task-point.png" alt=""></div>
            <div class="point-title">+{{ item.point }}</div>
          </div>
        </div>
        <div class="bottom">
          <img src="~@/assets/home/point-guide-3.png" alt="">
        </div>
      </div>
      <span class="footer" slot="footer">
        <div class="footer-btn" @click="close">我知道了</div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'point-guide',
  data () {
    return {
      list: [
        { id: '1', title: '1.每日首次登录', point: '3', img: require('@/assets/home/point-guide-1.png') },
        { id: '2', title: '2.基础课，上传个人资源后，将资源发布到全网并审核通过后', point: '50', img: require('@/assets/home/point-guide-2.png') },
        { id: '3', title: '3.个人中心，邀请新教师注册，每成功邀请一位教师', point: '100', img: require('@/assets/home/point-guide-4.png') }
      ]
    }
  },
  methods: {
    close () {
      this.$store.commit('baseCourse/setPointGuide', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.task-main {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  .task-body {
    width: 640px;
    height: 630px;
    background: #FFFFFF;
    border-radius: 10px;

    // opacity: 1 !important;
    .body-top {
      width: 100%;
      height: 64px;
      padding: 0 24px;
      background: linear-gradient(270deg, #FFE5BF 0%, #FDC26F 100%);
      font-size: 18px;
      color: #6B4A34;
      border-radius: 8px 8px 0 0;
      line-height: 64px;
      margin-bottom: 20px;
      .header-top {
        width: 100%;
        position: relative;
        .close {
          position: absolute;
          top: 0;
          right: -15px;
          cursor: pointer;
        }
      }
    }
  }
}
.content {
  width: 100%;
  padding: 0 24px;
  .task-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .item-left {
      width: 36px;
      height: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .item-center {
      width: 456px;
      font-size: 16px;
      color: #333333;
      margin: 0 5px;
    }
    .item-right {
      flex: 1;
      display: flex;
      align-items: center;
      .point-img {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .point-title {
        font-size: 14px;
        color: #FA6400;
        margin-left: 5px;
      }
    }
  }
  .bottom {
      width: 584px;
      height: 289px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
}
.footer {
  width: 100%;
  .footer-btn {
    width: 84px;
    height: 36px;
    background: #4061FF;
    border-radius: 5px;
    font-size: 16px;
    color: #FFFFFF;
    text-align: center;
    line-height: 36px;
    margin: 0 auto;
    margin-top: 30px;
    cursor: pointer;
  }
}
</style>
