<template>
  <div class="image-player" :style="{
    height: `${setH? '100%' : undefined}`
  }">
    <div :class="['box', isMax && 'max']" @mouseup="boxUp">
      <div class="img-box">
          <img v-drag id="mainImg" :src="imgSrc" alt="">
      </div>
      <div class="slider-box">
        <div @click="o2o" class="o2o"></div>
        <div @mousedown="imgMax" class="max"></div>
        <div @mousedown="imgMin" class="min"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'one-img',
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    setH: Boolean
  },
  data () {
    return {
      resId: '',
      isMax: false,
      source: ''
    }
  },
  created () {
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
    this.noRate = localStorage.getItem(this.resId + 'isMax') || this.source === '3' || this.source === 'undefined' || this.source === '4'
    localStorage.removeItem(this.resId + 'isMax')
  },
  mounted () {
    this.wheel()
  },
  methods: {
    boxUp () {
      clearInterval(this.longTimer)
    },
    imgMax () {
      const dom = document.getElementById('mainImg')
      this.nowTime = new Date().getTime()
      const oWidth = dom.offsetWidth
      const oHeight = dom.offsetHeight
      dom.style['max-width'] = 'none'
      dom.style['max-height'] = 'none'
      dom.style.width = (oWidth + 50) + 'px'
      dom.style.height = (oHeight + 50 / oWidth * oHeight) + 'px'
      clearInterval(this.longTimer)
      this.longTimer = setInterval(() => {
        const oWidth = dom.offsetWidth
        const oHeight = dom.offsetHeight
        dom.style.width = (oWidth + 20) + 'px'
        dom.style.height = (oHeight + 20 / oWidth * oHeight) + 'px'
      }, 17)
    },
    o2o () {
      const dom = document.getElementById('mainImg')
      dom.style.width = 'auto'
      dom.style.height = 'auto'
      dom.style.top = '50%'
      dom.style.left = '50%'
      dom.style['max-width'] = '100%'
      dom.style['max-height'] = '100%'
    },
    imgMin () {
      const dom = document.getElementById('mainImg')
      const oWidth = dom.offsetWidth
      const oHeight = dom.offsetHeight
      dom.style['max-width'] = 'none'
      dom.style['max-height'] = 'none'
      if (dom.offsetWidth > 800 || dom.offsetHeight > 600) {
        dom.style.width = oWidth - 50 + 'px'
        dom.style.height = oHeight - 50 / oWidth * oHeight + 'px'
      }
      clearInterval(this.longTimer)
      this.longTimer = setInterval(() => {
        const oWidth = dom.offsetWidth
        const oHeight = dom.offsetHeight
        if (dom.offsetWidth > 300 || dom.offsetHeight > 300) {
          dom.style.width = oWidth - 20 + 'px'
          dom.style.height = oHeight - 20 / oWidth * oHeight + 'px'
        }
      }, 17)
    },
    wheel () {
      const dom = document.getElementById('mainImg')
      console.log(104, dom)
      dom.onwheel = (e) => {
        const delta = (e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1))
        const dom = document.getElementById('mainImg')
        const oWidth = dom.offsetWidth
        const oHeight = dom.offsetHeight
        dom.style['max-width'] = 'none'
        dom.style['max-height'] = 'none'
        if (delta > 0) {
          dom.style.width = (oWidth + 50) + 'px'
          dom.style.height = (oHeight + 50 / oWidth * oHeight) + 'px'
        } else if (delta < 0) {
          if (dom.offsetWidth > 300 || dom.offsetHeight > 300) {
            dom.style.width = oWidth - 50 + 'px'
            dom.style.height = oHeight - 50 / oWidth * oHeight + 'px'
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.image-player{
  width: 100%;
  // height: 100%;
  height: calc(100vh - 300px);
  // height: 100%;
  .box{
    width:100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    &.max{
      height: 100%;
    }
    .img-box{
      position: relative;
      width:100%;
      height:100%;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      // overflow: hidden;
    }
  }
}
img{
   max-width: 100%;
    max-height: 100%;
    // width:100%;
    // height: calc(100% - 70px);
    // object-fit: scale-down;
    top:50%;
    left:50%;
    position: absolute;
    transform: translate(-50%,-50%);
}
.slider-box{
  // position: absolute;
  // bottom:50px;
  // left:50%;
  width: 80%;
  padding: 20px 0;
  // transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  div{
    cursor: pointer;
    width:40px;
    height: 40px;
    opacity: 1;
    position: relative;
    z-index: 100;
  }
  // &:hover{
  //   div{
  //     opacity: 1;
  //     transition: opacity ease 0.5s;
  //   }
  // }
  .o2o{
    margin:0 20px;
    background: url(~@/assets/o2o.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background-image: url(~@/assets/o2o_on.png);
    }
  }
  .prev{
    margin:0 20px;
    background: url(~@/assets/prev.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background-image: url(~@/assets/prev_on.png);
    }
  }
  .next{
    margin:0 20px;
    background: url(~@/assets/prev.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background-image: url(~@/assets/prev_on.png);
    }
  }
  .max{
    margin:0 20px;
    background: url(~@/assets/max.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background-image: url(~@/assets/max_on.png);
    }
  }
  .min{
    margin:0 20px;
    background: url(~@/assets/min.png) no-repeat;
    background-size: 100% 100%;
    &:hover{
      background-image: url(~@/assets/min_on.png);
    }
  }
  .turn{
    transform: rotateY(180deg);
  }
}
</style>
