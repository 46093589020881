import axios from '@/common/request/request'

// 获取全部问题帮助中心目录列表
export function getFAQList () {
  return axios.request({
    url: '/system/faq/list',
    method: 'GET'
  })
}
// 查询oos地址
export const postSignPathApi = (key, bucketName) => {
  return axios.request({
    url: '/system/ctyun/signPath',
    method: 'post',
    data: {
      key, bucketName
    }
  })
}
// 获取问题帮助节点详细信息
export function getFAQNode (faqId) {
  return axios.request({
    url: `/system/faq/${faqId}`,
    method: 'GET'
  })
}

// 根据条件查询视频教程列表
export function queryVideoList (params) {
  return axios.request({
    url: '/system/vod/list',
    method: 'GET',
    params
  })
}

// 根据区位码查询所在科协单位及数据
export function queryCastByCode (code, level) {
  return axios.request({
    url: '/system/cast/openapi/getCastByCode',
    method: 'GET',
    params: {
      code,
      level
    }
  })
}

// 获取启动页通知
// 根据区位码查询所在科协单位及数据
export function getMessageApi (code) {
  return axios.request({
    url: `/sysAnnouncement/${code}`,
    method: 'GET'
  })
}

// 获得系统菜单配置
export function getMenuListApi () {
  return axios.request({
    url: 'system/moduleMenu/openapi/pc/list',
    method: 'get'
  })
}

// 查询产品及解决方案
export function queryadList (params) {
  return axios.request({
    url: '/system/ad/openapi/pc/list',
    method: 'GET',
    params
  })
}

// 查询产品及解决方案详情
export function queryadDetail (params) {
  return axios.request({
    url: `/system/ad/${params}`,
    method: 'GET'
  })
}

// 获得系统配置资源类别
export function getResTypeApi (params) {
  return axios.request({
    url: 'res/resCourseResType/pc/list',
    method: 'GET',
    params
  })
}
// 查询积分任务列表
export function queryTaskList (params) {
  return axios.request({
    url: '/system/ucPointTask/taskList',
    method: 'GET',
    params
  })
}

// 领取积分任务积分
export function obtainUcTaskPoints (data) {
  return axios.request({
    url: '/system/ucPointTask/obtainUcTaskPoints',
    method: 'post',
    data
  })
}

// 是否完成了所有的积分任务
export function getIfOverAllTask (params) {
  return axios.request({
    url: '/system/ucPointTask/getIfOverAllTask',
    method: 'get',
    params
  })
}

// 绑定用户
export function bindUserByMobileApi (data) {
  return axios.request({
    url: '/auth/partnerCenter/openapi/pc/bindPartnerByMobile',
    method: 'post',
    data
  })
}

// sso 登录
export function ssoLoginApi (data) {
  return axios.request({
    url: '/auth/partnerCenter/openapi/ssoLogin',
    method: 'post',
    data
  })
}

// 参数设置
// 通过键值key获取value
export function getConfigValue (key) {
  return axios.request({
    url: `/system/config/configKey/${key}`,
    method: 'GET'
  })
}

// 365预览地址
export function get365Url (data) {
  return axios.request({
    url: '/system/ctyun/get365Url',
    method: 'post',
    data
  })
}

// OSS加签地址
export function getsignPath (data) {
  return axios.request({
    url: '/system/ctyun/signPath',
    method: 'post',
    data
  })
}

// OSS加签地址
export function getsignPublicBucketPath (data) {
  return axios.request({
    url: '/system/ctyun/signPublicBucketPath',
    method: 'post',
    data
  })
}
