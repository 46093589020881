import axios from 'axios'
import config from '@/config'
import { getToken } from '@/storage/viewStorage'
import { downloadSmallFileFn } from '@/common/event-bus/ipc-bridge-file'
import { Modal, Message } from 'view-design'
import { loginout } from '@/api/login'
import route from '@/router'
const MD5 = require('md5')

axios.defaults.headers.Authorization = 'Bearer ' + getToken()

const service = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro,
  timeout: 10000
})

// 通用下载方法
export function download (url, filename, requset = 'get', contentType = 'application/x-www-form-urlencoded') {
  return service[requset](url, {
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then((data) => {
    const content = data.data
    const blob = new Blob([content])
    if (content.type === 'application/json') {
      const reader = new FileReader()
      reader.readAsText(content)
      reader.addEventListener('loadend', (e) => {
        const result = JSON.parse(e.target.result)
        Modal.success({
          title: '提示',
          content: result.msg,
          okText: '确定',
          onOk: () => {
            setTimeout(() => {
              loginout().then(() => {
                // this.$store.commit('user/updateFullScreenStatus', false)
                route.push('/login')
              })
            }, 200)
          }
        })
      })
      return
    }
    if ('download' in document.createElement('a')) {
      const elink = document.createElement('a')
      elink.download = filename
      elink.style.display = 'none'
      elink.href = URL.createObjectURL(blob)
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
    } else {
      navigator.msSaveBlob(blob, filename)
    }
  }).catch((r) => {
    console.error(r)
  })
}

export function pcDownloadFileFn (url, filename, requset = 'get', fn) {
  fetch(url, {
    method: requset,
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + getToken()
    }
  }).then(res => {
    res.arrayBuffer().then(data => {
      console.log(data)
      downloadSmallFileFn(
        {
          name: filename,
          result: data
        },
        () => {
          fn && fn()
        }
      )
    })
    // const content = data.data
    // const blob = new Blob([content])
    // const reader = new FileReader()
    // reader.readAsArrayBuffer(blob)
    // reader.onloadend = () => {
    // }
  })
}

// pc端小文件下载
export function pcDownloadSmFn (url, filename, requset = 'get', fn) {
  return service[requset](url, {
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }).then((data) => {
    const content = data.data
    const blob = new Blob([content])
    const reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onloadend = () => {
      downloadSmallFileFn(
        {
          name: filename,
          result: reader.result
        },
        () => {
          fn && fn()
        }
      )
    }
  })
}

// 通过浏览器标识符生产机器id
export function getMachineIdenFn () {
  let res = ''
  const userAgent = JSON.stringify(navigator.userAgent)
  res = MD5(userAgent)
  return res
}

// 浏览器生成a标签并且自定义名称下载
export function dowmloadByATagCutomNameFn (url32, name, method = 'get', isToken) {
  let headers
  if (isToken) {
    headers = {
      Authorization: 'Bearer ' + getToken()
    }
  }
  const msg = Message.loading({
    content: '下载中...',
    duration: 0
  })
  fetch(url32, {
    method: method,
    responseType: 'blob',
    headers
  }).then(res => {
    if (window.navigator.msSaveBlob) {
      const blob = res.blob()
      navigator.msSaveBlob(blob, name)
    } else {
      res.arrayBuffer().then(res => {
        const binData = [res]
        const oA = document.createElement('a')
        oA.setAttribute('download', name)
        const url = URL.createObjectURL(new Blob(binData))
        oA.setAttribute('href', url)
        oA.click()
        // window.URL.revokeObjectURL(url)
        oA.remove(true)
        setTimeout(() => {
          msg()
        }, 1000)
      })
    }
  }).catch((err) => {
    console.log(err)
    const oA = document.createElement('a')
    oA.setAttribute('download', name)
    const url = url32 && url32.replace(/http\\:/, 'https:')
    oA.setAttribute('href', url)
    oA.click()
    // window.URL.revokeObjectURL(url)
    oA.remove(true)
    msg()
  })
}

// 浏览器生成 a 标签下载
export function dowmloadByATagFn (url32, name) {
  const oA = document.createElement('a')
  oA.setAttribute('download', name)
  const url = url32 && url32.replace(/http\\:/, 'https:')
  oA.setAttribute('href', url)
  oA.click()
  // window.URL.revokeObjectURL(url)
  oA.remove(true)
}

// pc端小文件保存
export const saveAsPC = (blob, filename) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(blob)
    reader.onloadend = () => {
      downloadSmallFileFn(
        {
          name: filename,
          result: reader.result
        },
        () => {
          resolve()
        }
      )
    }
  })
}
