import * as pageInfo from '@/config/page.js'
import pageConfig from '@/config/url.js'
const href = location.host

// const pageConfig = {
//   'qdpub.zkxdr.net': 'qd',
//   'kexue.mmedu.net.cn': 'qd',
//   'qdpub.zksr.net': 'qd',
//   'shkjpub.zkxdr.net': 'sk',
//   'shkjpub.zksr.net': 'sk',
//   'testone-saas.zksr.net': 'qd',
//   'test-saas.zksr.net': 'sk',
//   'localhost:8080': 'sk'
// }
// 用户信息
const appInfo = {
  namespaced: true,
  state: {
    name: 'zksr',
    config: pageInfo[pageConfig[href] || 'normal'],
    isTestEnv: href.search('test') >= 0 || href.search('localhost') >= 0
  },
  mutations: {},
  actions: {}
}

export default appInfo
