<template>
  <div class="file-wrap">
    <div v-html="text" class="text-wrap" :class="[isMax ? 'web-wrap-max' : 'web-wrap']">
    </div>
  </div>
</template>

<script>
import { getTextInfoApi } from '@/api/resources'
export default {
  name: 'text-edit',
  props: {
    resId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isMax: false,
      source: '',
      text: ''
    }
  },
  created () {
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
    this.noRate = localStorage.getItem(this.resId + 'isMax') || this.source === '3' || this.source === 'undefined' || this.source === '4'
    localStorage.removeItem(this.resId + 'isMax')
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
    this.getTextInfoFn()
  },
  methods: {
    getTextInfoFn () {
      getTextInfoApi(this.resId).then(res => {
        this.text = res.data && res.data.data && res.data.data.content
      })
    }
  }
}
</script>

<style scoped>
.file-wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.web-wrap {
  width: 100%;
  height: auto;
  /* overflow-y: auto; */
}
.web-wrap-max{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.rate-comp{
  width: 100%;
  height: 100px;
}
.text-wrap {
  padding: 20px;
}
</style>
