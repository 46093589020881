/* 资源库查看 */
<template>
  <div class="main_first">
    <div id="fullscreen" class="res-content" @keydown.esc="handleEsc">
      <div class="fixed-header flex">
        <div class="fixed-content flex">
          <div class="left flex">
            <div class="tag-icon">
              <img :src="chousImg[resces.contentType]" alt="" />
            </div>
            <div class="title">{{ resces.resName }}</div>
          </div>
        </div>
        <img
          class="img"
          src="@/assets/close-window.png"
          alt=""
          @click="closeMode"
        />
      </div>
      <div class="info-top" style="height: 40px">
        <div class="line-21">
          <div v-if="!resces.expertId && ((resces.source == '3') || !author.userId)" class="flex">
            <div class="img">
              <img :src="author.userAvatarUrl || defaultAvatarSrc" alt="" />
            </div>
            <div class="font-17">{{ author.author }}</div>
          </div>
          <Poptip v-else placement="top-start" trigger="hover">
            <div class="flex">
              <div class="img">
                <img :src="author.userAvatarUrl || defaultAvatarSrc" alt="" />
              </div>
              <div class="font-17">{{ author.author || resces.expertName }}</div>
            </div>
            <div slot="content" class="user-content">
              <div class="head">
                <img class="avatar1" :src="author.userAvatarUrl || defaultAvatarSrc" >
                <div class="name">
                  <div class="line1">{{ author.author || author.userName || resces.expertName || '' }}</div>
                  <div class="line2" v-if="!resces.expertId">小学 | 科学</div>
                </div>
              </div>
              <div class="info-wrap" v-if="!resces.expertId">
                <div class="left">
                  <span>资源数</span>
                  <span>点赞数</span>
                  <span>被收录</span>
                  <span>资源平均评分</span>
                </div>
                <div class="right">
                  <span>{{ userData.uploadCount || 0 }}</span>
                  <span>{{ userData.upSum || 0 }}</span>
                  <span>{{ userData.includeCount || 0 }}</span>
                  <span>{{ userData.resAvgScore || 0 }}分</span>
                </div>
              </div>
              <div v-show="author.userId" class="check" @click="onVisit">查看更多</div>
              <div v-show="resces.expertId" class="check" @click="onVisitExpertSpaceFn">查看更多</div>
            </div>
          </Poptip>
        </div>
        <!-- <div class="font-16 line-21">使用数：{{ author.downCnt || 0 }}</div> -->
      </div>
      <div class="fixed-body" :class="(!fullscreen && scale === 1) ? 'noRight' : 'noRight'" >
        <div class="body-left" id="playRes">
          <template v-if="resces.contentType === '4'">
            <resImg :imgSrc="formData.fileUrl" alt="" />
          </template>
          <div v-else-if="resces.contentType === '5'">
            <moreImg
              v-if="formData.graphs && formData.graphs.length"
              :arr="formData.graphs"
              :resId="formData.resId"
            />
          </div>
          <div
            class="page-wrap"
            v-else-if="resces.contentType === '6' || resces.contentType === '12' || resces.contentType === '13'"
          >
            <Video
              class="video"
              :isM3U8="isM3U8"
              :videoSrc="formData.fileUrl"
              :extName="resces.contentType"
            />
          </div>
          <div
            v-else-if="
              resces.contentType === '1' ||
              resces.contentType === '2' ||
              resces.contentType === '3' ||
              resces.contentType === '9'
            "
            class="page-wrap"
          >
            <iframe
              :class="fullshow ? 'page-content' : 'page-content-fullshow'"
              :src="formData.fileUrl"
              frameborder="0"
            ></iframe>
          </div>
          <template v-else-if="resces.contentType === '16'">
            <Txt :resId="resces.resId" />
          </template>
        </div>
        <div class="font-14 copy-right">
          版权说明：{{ resces.copyRight ? resces.copyRight : '官方版权归作者所有，如有侵权联系我们' }}
        </div>
        <div v-if="!fullscreen && scale === 1 && !isInElectron && fullshow" class="body-right">
          <div class="main-info">
            <span class="font-16">评分：</span>
            <span v-if="author.avgScore" class="score">
              <span class="font-24">{{ `${author.avgScore}`[0] }}</span>
              <span class="font-15">{{
                "." + `${author.avgScore.toFixed(1)}`[2]
              }}</span>
            </span>
            <span v-else class="score" style="color: #999999; font-size: 14px"
              >暂无评分</span
            >
            <span v-if="!isMax" class="score">|</span>
            <Poptip v-if="!isMax" trigger="click" placement="top" @on-popper-hide="onPopHide">
              <Button type="text" style="color: #fa6400">{{
                showScoreBtn ? "去评分" : "已评分"
              }}</Button>
              <div class="popover" slot="content">
                <div class="avatar">
                  <img src="@/assets/resource/rate-image.png" />
                </div>
                <div class="rate">
                  <Rate
                    v-model="score"
                    @on-change="rateChange"
                    :disabled="!showScoreBtn"
                  />
                  <span class="rateText">{{ scoreText }}</span>
                  <span
                    v-show="showScoreBtn"
                    v-click
                    class="button"
                    @click="changeScore"
                    >完成评分</span
                  >
                </div>
              </div>
            </Poptip>
          </div>
          <!-- <div class="font-16 line-21">
            <div>评分：<span class="font-24">{{ author.avgScore || 0 }}</span><span>|</span><span class="font-15">去评分</span></div>
          </div> -->
          <div class="flex line-21">
            <div v-if="!agree && !isMax" class="flex-btn" @click="onAgree">点赞</div>
            <div v-else-if="!isMax && agree" class="flex-btn" @click="notAgree">已赞</div>
            <div
              v-if="!isMax"
              class="flex-btn"
              style="margin-left: 10px"
              @click="model2 = true"
            >
              评论
            </div>
            <div v-if="fullshow && (resces.contentType !== '6' && resces.contentType !== '12' && resces.contentType !== '13')" class="full-screen" @click="adscreen">
              <img class="img" src="@/assets/resource/full-screen.png" alt="">
              <div>全屏</div>
            </div>
          </div>
        </div>
      </div>
      <Modal v-model="model2" width="560" :style="{'position': 'fixed','z-index':1099}">
        <div slot="header">
          <span>{{ resces.resName }}</span>
        </div>
        <div class="content">
          <div class="no-data_wrap" v-if="!comments.length">
            <no-data />
          </div>
          <div class="line" v-for="item in comments" :key="item.id">
            <div class="avatar">
              <img :src="item.userAvatarUrl || defaultAvatarSrc" />
            </div>
            <div class="cont">
              <div class="info">
                <span class="name">{{ item.userName }}</span>
                <span class="date">{{ item.createTime }}</span>
              </div>
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div slot="footer" class="send-area">
          <Input
            size="large"
            v-model="comment"
            placeholder="发表你的评论吧~"
            @keypress.enter="onSend"
          ></Input>
          <div @click="onSend" class="send-btn">
            <img src="@/assets/icon/send-icon.png" alt="" />
            <span>发送</span>
          </div>
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import {
  getOnlineSrcApi,
  getM3u8SrcApi,
  getResInfo,
  addResScore,
  getResScore,
  whetherAgree,
  cancelAgree,
  addAgree,
  listComment,
  addComment
} from '@/api/resources'
import { getUserId } from '@/storage/viewStorage'
import resImg from '@/view/webWindow/img'
import moreImg from '@/view/webWindow/component-imgs'
import Video from '@/view/webWindow/onlineVideo'
import Txt from '@/view/webWindow/text'
import NoData from '@/common/components/no-data/no-data'
import { getUserStatistics } from '@/api/myinfo'
export default {
  name: 'play-resources',
  components: {
    resImg,
    moreImg,
    Video,
    Txt,
    NoData
  },
  data () {
    return {
      fullshow: true,
      isMax: false,
      comments: [],
      model2: false,
      comment: '',
      isOnAgree: false,
      agree: false,
      agreeId: '',
      score: 0,
      rateText: ['待提升', '一般', '认可', '推荐', '五星好评'],
      scoreText: '',
      showScoreBtn: true,
      defaultAvatarSrc: require('@/assets/user-avatar-default.png'),
      scale: 1,
      fullscreen: false, // 是否全屏
      showHeader: true,
      ratio: 0,
      butTxt: '全屏',
      resces: undefined,
      author: {},
      userData: {},
      formData: {},
      chousImg: {
        1: require('@/assets/resource/word.png'),
        2: require('@/assets/resource/ppt.png'),
        3: require('@/assets/resource/excel.png'),
        4: require('@/assets/resource/oneImg.png'),
        5: require('@/assets/resource/moreImg.png'),
        6: require('@/assets/resource/video.png'),
        9: require('@/assets/resource/pdf-res.png'),
        8: require('@/assets/resource/3d.png'),
        12: require('@/assets/resource/video.png'),
        13: require('@/assets/resource/video.png'),
        16: require('@/assets/resource/word.png')
      },
      isM3U8: false
    }
  },
  created () {
    this.resces = this.$store.state.baseCourse.resourcesItem
    this.getWidth()
    this.isMax = this.resces.source === '3' || this.resces.resId === 'undefined' || this.resces.source === '4'
  },
  computed: {
    userId () {
      return getUserId()
    },
    isInElectron () {
      const flag = navigator.userAgent.indexOf('Electron') > -1
      return flag
    }
  },
  mounted () {
    this.initData()
    this.resScore()
    this.hasAgree(true)
    this.queryList()
    document.addEventListener('contextmenu', this.setContextMenuFn)
    document.addEventListener('fullscreenchange', this.listenEsc)
  },
  beforeDestroy () {
    document.removeEventListener('contextmenu', this.setContextMenuFn)
    document.removeEventListener('fullscreenchange', this.listenEsc)
  },
  methods: {
    getFullscreenElement () {
      return (
        document.fullscreenElement || document.mozFullScreenElement || document.msFullScreenElement || document.webKitFullscreenElement || null
      )
    },
    listenEsc (e) {
      if (this.getFullscreenElement() === null) {
        this.fullshow = true
      }
    },
    adscreen () {
      const fullscreen = document.getElementById('fullscreen')
      fullscreen.requestFullscreen()
      this.fullshow = false
    },
    initData () {
      getResInfo(this.resces.resId).then((res) => {
        this.author = res.data.data
        this.statistics(this.author.userId)
      })
      if (this.resces.contentType === '16') {
        return
      }
      getOnlineSrcApi(this.resces.resId, this.resces.courseId).then((res) => {
        const data = res.data.data
        if (data.tsStatus === 1) {
          getM3u8SrcApi(data.tsPid).then(res => {
            this.isM3U8 = true
            data.fileUrl = res.data.data
            this.formData = data
          })
        } else {
          this.isM3U8 = false
          this.formData = data
        }
      }).catch(() => {
        this.closeMode()
      })
    },
    statistics (userId) {
      getUserStatistics(userId).then(res => {
        this.userData = res.data.data
        if (this.isOnAgree) {
          this.isOnAgree = false
        }
      })
    },
    onVisit () {
      const url = window.location.origin
      const baseUrl = url + '/#/user-space?userId=' + this.author.userId
      window.open(baseUrl, '_blank')
    },
    onVisitExpertSpaceFn () {
      const url = window.location.origin
      const baseUrl = `${url}/#/expert-space?id=${this.resces.expertId}&name=${this.resces.expertName}`
      window.open(baseUrl, '_blank')
    },
    queryList () {
      listComment(this.resces.resId).then((res) => {
        this.comments = res.data.data
      })
    },
    onSend () {
      if (!this.comment || !this.comment.trim()) {
        this.$Message.error('请输入评论内容')
        return
      }
      addComment(this.resces.resId, this.comment).then((res) => {
        if (res.data.code === 200) {
          this.$Message.success('发表成功!')
          this.comment = ''
          this.queryList()
        }
      })
    },
    onAgree () {
      if (this.isOnAgree) return
      this.isOnAgree = true
      addAgree(this.resces.resId).then((res) => {
        this.agree = true
        // this.resource.upNum += 1
        this.isOnAgree = false
        if (res.data.code === 200) {
          // this.resInfo()
          setTimeout(() => {
            this.hasAgree()
          }, 1000)
        }
      })
    },
    notAgree () {
      if (!this.agreeId) {
        this.eventList.push('notAgree')
      }
      if (this.isOnAgree) return
      this.isOnAgree = true
      cancelAgree(this.agreeId, this.resces.resId).then((res) => {
        this.agree = false
        this.isOnAgree = false
        // this.resource.upNum -= 1
        // if (res.data.code === 200) {
        //   // this.resInfo()
        //   // this.hasAgree(true)
        // }
      })
    },
    hasAgree (flag) {
      whetherAgree(this.resces.resId).then((res) => {
        const data = res.data
        if (data.data) {
          this.agreeId = data.data.id
          if (flag) {
            this.agree = true
          }
        } else {
          this.agree = false
        }
        if (this.isOnAgree) {
          this.isOnAgree = false
        }
      })
    },
    resScore () {
      getResScore(this.userId, this.resces.resId).then((res) => {
        const data = res.data
        if (data.rows && data.rows.length) {
          this.score = data.rows[0].score
          if (this.score) {
            this.scoreText = this.rateText[this.score - 1]
            this.showScoreBtn = false
          }
        }
      })
    },
    onPopHide () {
      if (this.showScoreBtn) {
        this.score = 0
        this.scoreText = ''
      }
    },
    rateChange (val) {
      this.scoreText = this.rateText[val - 1]
    },
    changeScore () {
      if (!this.score) {
        this.$Message.error('请选择评星')
        return
      }
      const data = {
        resId: this.resces.resId,
        userId: this.userId,
        score: this.score
      }
      addResScore(data).then((res) => {
        if (res.data.code === 200) {
          this.showScoreBtn = false
          getResInfo(this.resces.resId).then((res) => {
            this.author = res.data.data
          })
        }
      })
    },
    getWidth () {
      const screenWidth = window.screen.width
      this.ratio = screenWidth / 1200
    },
    fullScreens1 () { // 还原
      this.fullscreen = false
      this.scale = 1
      const playRes = document.getElementById('playRes')
      playRes.style.transform = 'scale(' + 1 + ')'
      playRes.style.transformOrigin = '50% 0'
      playRes.style.width = '910px'
    },
    fullScreens () { // 全屏
      this.fullscreen = true
      this.scale = 1.4
      const playRes = document.getElementById('playRes')
      // playRes.style.transform = 'scale(' + 1.6 + ')'
      // playRes.style.transformOrigin = '50% 0'
      playRes.style.width = '100%'
      // if (this.fullScreen) {
      //   this.$nextTick(() => {
      //     playRes.style.transform = 'scale(' + (this.ratio - 0.02) + ')'
      //     playRes.style.transformOrigin = '50% 0'
      //   })
      // } else {
      //   playRes.style.transform = 'scale(' + 1 + ')'
      //   playRes.style.transformOrigin = '50% 0'
      // }
    },
    amp () {
      this.$nextTick(() => {
        this.fullscreen = false
        const playRes = document.getElementById('playRes')
        this.scale = this.scale + 0.2
        playRes.style.transform = 'scale(' + this.scale + ',' + 1 + ')'
        playRes.style.transformOrigin = '50% 0'
      })
    },
    lessen () {
      const playRes = document.getElementById('playRes')
      playRes.style.width = '910px'
      this.$nextTick(() => {
        this.fullscreen = false
        const playRes = document.getElementById('playRes')
        this.scale = this.scale - 0.2
        playRes.style.transform = 'scale(' + this.scale + ',' + 1 + ')'
        playRes.style.transformOrigin = '50% 0'
      })
    },
    closeMode () {
      this.$store.commit('baseCourse/setPlayRes', false)
      document.body.style.overflow = ''
    },
    setContextMenuFn (event) {
      event.returnValue = false
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/globals.scss";
.noRight {
  justify-content: center;
}
.haveRight {
  justify-content: space-between;
}
::v-deep .user-content{
  padding: 0 16px 4px;
  width: 220px;
  .head{
    display: flex;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #e8eef7;
    .avatar1{
      height: 44px;
      width: 44px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .name{
      .line1{
        font-size: 16px;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .line2{
        color: #999999;
      }
    }
  }
  .info-wrap{
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    .left{
      display: inline-flex;
      flex-direction: column;
    }
    .right{
      display: inline-flex;
      flex-direction: column;
      align-items: flex-end;
      color: #495AFF;
    }
  }
  .check{
    line-height: 40px;
    text-align: center;
    color: #495AFF;
    cursor: pointer;
    border-top: 1px solid #e8eef7;
  }
}
::v-deep .ivu-modal-header {
  background-color: #4061ff;
  color: #ffffff;
  padding: 0 12px;
  height: 48px;
  line-height: 48px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  border-radius: 6px 6px 0 0;
}
::v-deep .ivu-modal-body {
  background-color: #f7f7f7;
  padding: 20px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
  // @extend .scroll;
  .line {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    flex-flow: row nowrap;
    padding: 10px;
    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .cont {
      display: flex;
      flex-direction: column;
      width: calc(100% - 50px);
      .text {
        font-size: 16px;
        color: #555555;
        word-break: break-all;
      }
      .info {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        .name {
          color: #4061ff;
        }
        .date {
          font-size: 14px;
          color: #999999;
        }
      }
    }
  }
}
::v-deep .ivu-modal-footer {
  padding: 0;
  .ivu-input-search {
    width: 100px;
    border-top-right-radius: 0;
    background-color: #4061ff;
  }
  .ivu-input {
    height: 48px;
    border-radius: 0 0 0 6px;
    &:focus {
      box-shadow: none;
      outline: $main-color;
    }
  }
}
::v-deep .send-area {
  display: flex;
  .ivu-input {
    width: 464px;
  }
  .send-btn {
    width: 96px;
    height: 48px;
    flex-shrink: 0;
    background: $main-color;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 6px 0;
    img {
      margin-right: 5px;
    }
  }
  .ivu-input-search {
    background: $main-color !important;
    border: 0;
    &:hover {
      outline: none;
    }
  }
}
::v-deep .ivu-modal-content .ivu-modal-close .ivu-icon-ios-close {
  color: #fff;
}
.file-wrap {
  width: 100%;
}
.page-content {
  width: 100%;
  height: 480px;
  // height: calc(100vh - 300px);
}
.page-content-fullshow {
  width: 100%;
  height: calc(100vh - 300px)
}
.video {
  width: 100%;
}
.flex {
  display: flex;
  align-items: center;
}
.main_first {
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  position: fixed;
   top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  .res-content {
    width: 900px;
    // height: 100%;
    height: 800px;
    overflow-y: auto;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .no-data_wrap {
    height: 360px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fixed-header {
    width: 100%;
    background: #ffffff;
    height: 80px;
    justify-content: center;
    align-items: center;
    position: relative;
    .img {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 15px;
      cursor: pointer;
    }
    .fixed-content {
      .left {
        justify-content: center;
        .tag-icon {
          width: 26px;
          height: 26px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          padding: 0 10px;
          max-width: 600px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .small-title {
          font-size: 16px;
          color: #666666;
        }
      }
    }
  }
  .fixed-body {
    flex: 1;
    width: 100%;
    // height: 100%;
    overflow-y: auto;
    // margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .body-left {
      padding-top: 20px;
      width: 100%;
      // height: 100%;
      background: #ffffff;
      overflow: hidden;
      // height: 500px;
      flex: 1;
    }
    .body-right {
      width: 100%;
      background: #ffffff;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      position: relative;
      height: 56px;
      .main-info {
        display: inline-flex;
        align-items: center;
        ::v-deep .ivu-poptip-body {
          padding: 0;
          .ivu-poptip-body-content {
            overflow: hidden;
            .avatar {
              width: 220px;
              height: 123px;
              img {
                width: 100%;
                height: 100%;
                /*border-radius: 6px 6px 0 0;*/
              }
            }
            .rate {
              padding: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              .rateText {
                color: #fa6400;
                display: block;
                height: 22px;
              }
              .button {
                margin-top: 10px;
                padding: 5px 10px;
                background-color: #fa6400;
                color: #ffffff;
                border-radius: 8px;
                cursor: pointer;
              }
            }
          }
        }
        .title {
          display: inline-block;
          font-size: 20px;
          color: #333333;
          min-width: 80px;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .score {
          margin-left: 15px;
          font-size: 16px;
          color: #fa6400;
          display: inline-flex;
          align-items: baseline;
          .fz-16 {
            font-weight: 900;
          }
          /*border-right: 1px solid #FA6400;*/
        }
      }
      .full-screen {
        width: 64px;
        height: 64px;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.1);
        border-radius: 50%;
        text-align: center;
        // line-height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #272E3B;
        cursor: pointer;
        position: absolute;
        right: 32px;
        bottom: 120px;
        .img {
          width: 18px;
          height: 18px;
        }
      }
      .line-21 {
        margin: 10px 0;
      }
      .font-16 {
        font-size: 16px;
        color: #666666;
      }
      .font-14 {
        font-size: 14px;
        color: #b7b7b7;
      }
      .font-24 {
        font-size: 24px;
        color: rgba(250, 100, 0, 1);
      }
      .font-15 {
        font-size: 16px;
        color: rgba(250, 100, 0, 1);
      }
      .font-17 {
        font-size: 16px;
        color: rgba(64, 97, 255, 1);
      }
      .flex-btn {
        width: 77px;
        height: 36px;
        border: 1px solid #4061ff;
        border-radius: 5px;
        text-align: center;
        line-height: 36px;
        color: #4061ff;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
.info-top {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  .img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.copy-right {
  text-align: center;
  margin-top: 24px;
  margin-bottom: 50px;
}
</style>
