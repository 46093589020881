<template>
  <video
    id="my-video"
    class="video-js"
    autoplay="autoplay"
    controls
    playsinline
    webkit-playsinline
    controlslist="nodownload"
  ></video>
</template>
<script>
import videoJs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  name: 'video-comp',
  props: {
    videoSrc: {
      type: String,
      default: ''
    },
    isM3U8: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      player: null,
      duration: null
    }
  },
  mounted () {
    console.log(454)
    const that = this
    setTimeout(() => {
      let source = []
      if (this.isM3U8) {
        source = [
          {
            src: this.videoSrc,
            type: 'application/x-mpegURL'
          }
        ]
      } else {
        source = [
          {
            src: this.videoSrc
          }
        ]
      }
      const player = videoJs('my-video', {
        autoplay: true,
        // controls: false,
        fluid: false,
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        language: 'zh-CN',
        controlBar: {
          children: [
            { name: 'playToggle' }, // 播放按钮
            { name: 'currentTimeDisplay' }, // 当前已播放时间
            { name: 'progressControl' }, // 播放进度条
            { name: 'durationDisplay' }, // 总时间
            // { // 倍数播放
            //   name: 'playbackRateMenuButton',
            //   'playbackRates': [0.5, 1, 1.5, 2, 2.5]
            // },
            {
              name: 'volumePanel', // 音量控制
              inline: false // 不使用水平方式
            },
            { name: 'FullscreenToggle' } // 全屏
          ]
        },
        sources: source
      })
      player.on('ready', function () {
        player.play().then(res => {
          that.duration = player.duration()
        })
      })
      player.on('play', function () {
        that.$emit('play')
      })
      player.on('pause', function () {
        that.$emit('pause')
      })
      this.player = player
    }, 66)
    document.addEventListener('contextmenu', this.setContextMenuFn)
    document.addEventListener('visibilitychange', () => {
      this.stopVideoFn()
    })
  },
  methods: {
    stopVideoFn () {
      this.player.pause()
    },
    playVideoFn () {
      this.player.play()
    },
    setContextMenuFn (event) {
      event.returnValue = false
      return false
    }
  },
  beforeDestroy () {
    this.player && this.player.dispose()
    document.removeEventListener('contextmenu', this.setContextMenuFn)
  }
}
</script>
<style lang="scss" scoped>
#my-video {
  width: 100%;
  min-height: 475px;
  height: 100%;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
