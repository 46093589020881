import { getUserDownloadInfoApi, getUserUploadInfoApi } from '@/api/myinfo'
import { rankInfo } from '@/api/points'
import { queryCertInfo } from '@/api/teacher'
// 用户信息
const userInfo = {
  namespaced: true,
  state: {
    webSite: '', // 用户科协地址
    sumCount: 0, // 用户下载总次数
    count: 0, // 用户已经下载次数
    availableSize: 0,
    availableSizeNum: 1,
    usedSize: 0,
    usedSizeNum: 0,
    uploadPer: 0,
    devUploadSize: 0, // 正在上传的容量
    vip: 0,
    points: 0,
    availablePoints: 0, // 可用积分
    certInfo: {} // 教师认证详情
  },
  mutations: {
    setWebSite (state, flag) {
      state.webSite = flag
    },
    setDownloadInfo (state, data) {
      state.sumCount = data.sumCount || 0
      state.count = data.count || 0
    },
    setUploadInfo (state, data) {
      state.uploadPer = parseInt((data.usedSize + state.devUploadSize) / data.availableSize * 100) > 100 ? 100 : parseInt(data.usedSize / data.availableSize * 100)
      state.availableSize = (data.availableSize && parseInt((data.availableSize / 1024 / 1024 / 1024) * 100) / 100) || 0
      state.usedSize = (data.usedSize && parseInt((data.usedSize + state.devUploadSize) / 1024 / 1024 * 100) / 100) || 0
      state.availableSizeNum = data.availableSize
      state.usedSizeNum = data.usedSize
    },
    setUsedSize (state, size) {
      state.devUploadSize += size
      if (size < 0) return
      state.usedSize = (parseInt((state.usedSizeNum + state.devUploadSize) / 1024 / 1024 * 100) / 100) || 0
      state.uploadPer = parseInt((state.usedSizeNum + state.devUploadSize) / state.availableSizeNum * 100) > 100 ? 100 : parseInt((state.usedSizeNum + state.devUploadSize) / state.availableSizeNum * 100)
    },
    setVipInfo (state, data) {
      state.vip = data
    },
    setAvailablePoints (state, data) {
      state.availablePoints = data
    },
    setPoints (state, data) {
      state.points = data
    },
    setCertInfo (state, data) {
      state.certInfo = data
    }
  },
  actions: {
    // 获取用户下载次数
    getUserDownloadInfoFn ({ commit }, userId) {
      getUserDownloadInfoApi(userId).then(res => {
        commit('setDownloadInfo', (res.data && res.data.data) || 1)
      })
    },
    // 获取用户上传容量
    getUserUploadInfoFn ({ commit }, userId) {
      return getUserUploadInfoApi(userId).then(res => {
        const data = (res.data && res.data.data) || 0
        commit('setUploadInfo', data)
        return data
      })
    },
    getUserPoints ({ commit }) {
      return rankInfo().then(res => {
        const apoint = (res.data && res.data.data && res.data.data.availablePoints) || 0
        const point = (res.data && res.data.data && res.data.data.point) || 0
        commit('setAvailablePoints', apoint)
        commit('setPoints', point)
      })
    },
    // 获取用户(教师)认证详情
    getUserCertInfo ({ commit }) {
      return queryCertInfo().then(res => {
        const info = res.data && res.data.data
        commit('setCertInfo', info)
        return info
      })
    }
  }
}

export default userInfo
