class Storage {
  constructor (storage) {
    this.storage = storage
  }

  setItem (key, value) {
    this.storage.setItem(key, value)
  }

  getItem (k) {
    return this.storage.getItem(k)
  }

  removeItem (k) {
    this.storage.removeItem(k)
  }

  clear () {
    this.storage.clear()
  }
}

export default Storage
