import axios from '@/common/request/request'

// 上传文件
export const bigfileUpload = formData => {
  return axios.request({
    url: '/res/upload/bigfileUpload',
    data: formData,
    transformRequest: [
      () => {
        return formData
      }
    ]
  })
}

// 获取章节下拉树列表
export const treeselect = params => {
  return axios.request({
    method: 'get',
    url: 'res/chapter/pc/treeselect',
    params: params
  })
}

// 分页查询用户课程的章节下资源列表
export const selectUerResResourceList = params => {
  if (params.chapterId < 0) return Promise.resolve({ data: { rows: [], code: 200 } })

  return axios.request({
    method: 'get',
    url: 'res/resource/pc/selectUerResResourceList',
    params: {
      ...params,
      date: Date.now()
    }
  })
}

// 分页查询云资源信息列表(pc前端)
export const selectYunResResourceList = params => {
  return axios.request({
    method: 'get',
    url: 'res/resource/pc/selectYunResResourceList',
    params: params
  })
}

// 跑马灯
export const getAudit = params => {
  return axios.request({
    method: 'get',
    url: 'res/uploadlog/pc/getAudit',
    params: params
  })
}

// 获取贡献榜
export const rankingList = params => {
  return axios.request({
    method: 'get',
    url: 'system/ucUser/rankingList',
    params: params
  })
}

// 上传云账号(pc端)
export const uploadYunZh = params => {
  return axios.request({
    url: 'res/resource/pc/uploadYunZh',
    data: params
  })
}

// 发布与资源库
export const publish = params => {
  return axios.request({
    url: 'res/resource/pc/publish',
    data: params
  })
}

// 删除资源
export const resourcesDel = params => {
  return axios.request({
    url: 'res/store/pc/delete',
    data: params
  })
}

// 系统资源复制到应用后在服务器新增记录
export const uploadLocalResource = params => {
  return axios.request({
    url: 'res/resource/pc/uploadLocalResource',
    data: params
  })
}

// 获取资源类型
export const resResourceType = params => {
  return axios.request({
    method: 'get',
    url: 'system/dict/data/pc/type/res_resource_type',
    params: params
  })
}

// 获取教材版本
export const resMaterialVersion = params => {
  return axios.request({
    method: 'get',
    url: 'system/dict/data/pc/type/res_material_version',
    params: params
  })
}

// 获取章节
export const getChapter = params => {
  return axios.request({
    method: 'get',
    url: 'res/chapter/pc/getChapter',
    params: params
  })
}

// 获取学段
export const sysStudyPhaseType = params => {
  return axios.request({
    method: 'get',
    url: 'system/dict/data/pc/type/sys_study_phase_type',
    params: params
  })
}

// 获取学科类型
export const sysCourseType = params => {
  return axios.request({
    method: 'get',
    url: 'system/dict/data/pc/type/sys_course_type',
    params: params
  })
}

// 获取限定的学科类型
export const sysAuthCourseType = params => {
  return axios.request({
    method: 'get',
    url: 'system/teacher/geTeacherCourseList',
    params: params
  })
}

// 根据学段获取学科类型
export const getSubjectTypeBySectionApi = (courseType, section, subject) => {
  return axios.request({
    method: 'get',
    url: 'system/subjectVer/list',
    params: {
      courseType,
      section,
      subject
    }
  })
}

// 获取资源格式（云资源库）
export const resFileType = params => {
  return axios.request({
    method: 'get',
    url: 'system/dict/data/pc/type/res_file_type',
    params: params
  })
}

// 插入到教材目录
export const binding = params => {
  return axios.request({
    url: 'res/userCourseResource/pc/binding',
    data: params
  })
}

// 获取教材列表
export const findAll = params => {
  return axios.request({
    method: 'get',
    url: 'res/course/pc/findAll',
    params: params
  })
}
// 获取教材列表
export const findSchlAll = params => {
  return axios.request({
    method: 'get',
    url: 'res/schlCourse/list',
    params: params
  })
}

// 添加下载队列-前台
export const addDownloadQueue = params => {
  return axios.request({
    url: 'res/resdown/addDownloadQueue',
    method: 'post',
    data: params
  })
}

// 下载资源-前台
export const download = params => {
  return axios.request({
    url: 'res/resdown/download',
    data: params
  })
}

// 通知后台更新状态
export const downlog = params => {
  return axios.request({
    url: 'res/resdown/downLog',
    data: params
  })
}

export function uploadcallback (fileKey) {
  return axios.request({
    url: '/res/ctyun/copy',
    method: 'post',
    data: { key: fileKey }
  })
}
// 分页查询用户下载列表-前台
export const resdownList = params => {
  return axios.request({
    method: 'get',
    url: 'res/resdown/list',
    params: params
  })
}

// 上课资源清单(pc前端)
export const selectUserLessonResList = params => {
  return axios.request({
    method: 'get',
    url: 'res/resource/pc/selectUserLessonResList',
    params: params
  })
}

// 本地不存在文件时通知服务器更新资源状态
export const pcReset = params => {
  return axios.request({
    url: 'res/store/pc/reset',
    data: params
  })
}

// 获取下载链接
export const downLink = params => {
  return axios.request({
    url: 'res/resdown/downLink',
    data: params
  })
}

// 获取云资源标签类型
export const resTopicTag = params => {
  return axios.request({
    method: 'get',
    url: 'system/dict/data/pc/type/res_topic_tag',
    params: params
  })
}

// 设置默认教材
export const editDefaultCourse = courseId => {
  return axios.request({
    url: `system/myInfo/editDefaultCourse/${courseId}`
  })
}

// 验证邀请码
export const invitationVerify = params => {
  return axios.request({
    url: 'system/invitation/verify',
    data: params
  })
}

// 获取资源绑定关系数量接口
export const getCnt = params => {
  return axios.request({
    url: 'res/userCourseResource/pc/getCnt',
    data: params
  })
}

// 修改资源名称
export const editName = params => {
  return axios.request({
    url: 'res/userCourseResource/pc/editName',
    data: params
  })
}

// 修改资源排序
export const pcSort = params => {
  return axios.request({
    url: 'res/userCourseResource/pc/sort',
    data: params
  })
}

// 初始化清空资源的下载列表
export const clearResDownloadList = () => {
  return axios.request({
    url: 'res/resdown/clearLog',
    method: 'post'
  })
}

export const queryResChapter = (resId) => {
  return axios.request({
    url: `res/resource/reschapter/${resId}`,
    method: 'get'
  })
}

// 检测资源是否已经上传
export const fileCheck = (params) => {
  return axios.request({
    url: 'res/file/check',
    params: params,
    method: 'get'
  })
}

// 通过courseId取教材版本
export const getVerByCourseId = (courseId) => {
  return axios.request({
    url: `/res/course/${courseId}`,
    method: 'GET'
  })
}

// 上传视频回调整函数
export function uploadVideoCb (fileKey, size, filename) {
  return axios.request({
    url: '/lab/log',
    method: 'post',
    data: {
      path: fileKey, size, filename
    }
  })
}
// 在线查看地址
export function getOnlineSrcApi (id, courseId) {
  return axios.request({
    url: `/res/resource/getOnlineUrl/${id}`,
    method: 'get'
  })
}

export function getM3u8SrcApi (id, type = 'res') {
  return axios.request({
    url: `/${type}/hls/getUrlByTsPid/${id}`,
    method: 'get'
  })
}

// 获取富文本详情
export function getTextInfoApi (id) {
  return axios.request({
    url: `/res/resResourceText/one/${id}`,
    method: 'get'
  })
}

// 获取个人资源下载地址
export function getPersonalSrcApi (id) {
  return axios.request({
    url: `/res/offline/listUp/${id}`,
    method: 'get'
  })
}

// 根据条件查询教材版本
export const getVerBysubject = params => {
  return axios.request({
    method: 'get',
    url: `system/subjectVer/subject/${params}`
    // params: params
  })
}
// 获取分类资源数量
export const getResouceNumByTypeApi = params => {
  if (params.chapterId < 0) return Promise.resolve({ data: { row: [] } })
  return axios.request({
    method: 'get',
    url: '/res/resource/pc/selectUerResResourceGroupResTypeCount',
    params: {
      ...params,
      date: Date.now()
    }
  })
}
// 获取社团我的课程分类资源数量
export const getClubResouceNumByTypeApi = params => {
  return axios.request({
    method: 'get',
    url: '/res/clubUserResource/pc/selectClubMyCourseResGroupResTypeCount',
    params: params
  })
}
// 获取社团标准课程分类资源数量
export const getStanderClubResouceNumByTypeApi = params => {
  return axios.request({
    method: 'get',
    url: '/res/clubUserResource/pc/selectClubUserResourceGroupResTypeCount',
    params: params
  })
}
// 获取备课模式下相关云端课程
export const getRelationUerResResourceListApi = params => {
  return axios.request({
    method: 'get',
    url: '/res/resource/pc/selectRelationUerResResourceList',
    params: params
  })
}
// 保存章节资源
export const postSaveResApi = data => {
  return axios.request({
    method: 'post',
    url: '/res/resource/pc/saveResUserCustomResource',
    data
  })
}
// 资源评论列表
export function listComment (resId, userId) {
  return axios.request({
    url: '/res/resUserDiscuss/list',
    method: 'GET',
    params: {
      userId, resId
    }
  })
}

// 添加资源评论
export function addComment (resId, content) {
  return axios.request({
    url: '/res/resUserDiscuss/add',
    method: 'POST',
    data: {
      resId, content
    }
  })
}

// 查询资源是否点赞
export function whetherAgree (resId) {
  return axios.request({
    url: `/res/resAggData/user/${resId}`,
    method: 'GET'
  })
}

// 点赞
export function addAgree (resId) {
  return axios.request({
    url: '/res/resAggData/up',
    method: 'POST',
    data: {
      resId
    }
  })
}
// 取消点赞
export function cancelAgree (id, resId) {
  return axios.request({
    url: '/res/resAggData/cancelUp',
    method: 'POST',
    data: {
      id, resId
    }
  })
}

// 查询资源的详情信息
export function getResInfo (resId) {
  return axios.request({
    url: '/res/resource/getResourceInfo',
    method: 'GET',
    params: {
      resId
    }
  })
}

// 查询用户资源评分
export function getResScore (userId, resId) {
  return axios.request({
    url: '/res/resUserScore/list',
    method: 'GET',
    params: {
      resId, userId
    }
  })
}

// 资源评分
export function addResScore ({ resId, userId, score }) {
  return axios.request({
    url: '/res/resUserScore/add',
    method: 'POST',
    data: {
      resId, userId, score
    }
  })
}

// 获取课程公共推荐资源
/**
 * data
 * @params {resType}
 * @params {courseId}
 * @params {chapterId}
 * @params {resIds}
 */
export const getCommonRecommendApi = data => {
  return axios.request({
    method: 'POST',
    // url: `recommender/calculate/resrand`,
    url: 'res/resource/getRecommendResourceList',
    data
  })
}

// 查询知识树列表
export function listTree (query) {
  return axios.request({
    url: '/res/tree/list',
    method: 'get',
    params: query
  })
}

// 初次兑换
export const postExchangeExCodeApi = data => {
  return axios.request({
    method: 'POST',
    url: 'res/resExCode/exchangeExCode',
    data
  })
}

// 二次兑换
export const postReExchangeExCodeApi = data => {
  return axios.request({
    method: 'POST',
    url: 'res/resExCode/exchangeExCodeByUuid  ',
    data
  })
}

// 获取兑换的资源列表
export const getExchangeListApi = params => {
  return axios.request({
    method: 'get',
    url: 'res/resExCode/pc/queryUserExchangeList',
    params
  })
}
