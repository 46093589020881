import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import filters from '@/common/filters'
import config from './config/index'
// import 'bootstrap/dist/css/bootstrap.min.css'
// 引入重置样式
import './style/reset.scss'
// 引入自定义 iview 样式
import './style/iview.scss'
// 引入统一自定义样式
import './style/style.scss'

// 自定义的组件库
// import './components/base/base-components'

// 按需引入iview组件
import '@/common/components/iview/dynamic-components'

import directives from '@/directive/index'
import hevueImgPreview from 'hevue-img-preview'
Vue.use(directives, { store })
Vue.use(hevueImgPreview)
// Vue.http = Vue.prototype.$http = axios
Vue.config.productionTip = false
// 键盘监听
Vue.config.keyCodes.f5 = 116
for (const key in filters) {
  if (Object.hasOwnProperty.call(filters, key)) {
    const element = filters[key]
    Vue.filter(key, element)
  }
}
Vue.prototype.inElectron = () => {
  const flag = navigator.userAgent.indexOf('Electron') > -1
  return flag
}

// 百度统计
if (config.bdsiteId) {
  var _hmt = _hmt || []
  window._hmt = _hmt; // 修改为window 全局变量
  (function () {
    const hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?' + config.bdsiteId
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
}
/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  store,
  template: '<App/>'
}).$mount('#app')
