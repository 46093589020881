import { throttlePlus } from '@/common/libs/utils'
export default {
  install: function (Vue, opts) {
    const store = opts.store
    // 指令
    Vue.directive('click', {
      bind (el, binding, vnode) {
        const elArr = []
        el.addEventListener('click', function (e) {
          el.classList.add('click-ripple')
          const div = document.createElement('div')
          div.classList.add('ripple-animation')
          el.appendChild(div)
          elArr.push(div)
          setTimeout(() => {
            if (elArr.length > 0) {
              elArr[0].remove()
              elArr.shift()
            }
            el.classList.remove('click-animation')
          }, 250)
        })
      }
    })
    // 百度统计
    Vue.directive('baidu', {
      bind: function (el, binding, vnode) {
        // 这个bind只执行1次
        el.addEventListener('click', function () {
          // 百度统计即将收费,我们依然采用 _trackPageview
          window._hmt && window._hmt.push(['_trackPageview', '#' + binding.value])
          // alert(window._hmt)
          console.log(['_trackEvent', binding.value])
        })
      }
    })

    Vue.directive('drag',
      function (el, binding) {
        const oDiv = el
        oDiv.onmousedown = function (e) {
          e.preventDefault()
          const bw = document.body.clientWidth
          const bh = document.body.clientHeight
          const disX = e.clientX - oDiv.offsetLeft
          const disY = e.clientY - oDiv.offsetTop
          document.onmousemove = throttlePlus(function (e) {
            let l = 0
            let t = 0
            if (e.clientX >= bw) {
              l = bw - disX
            } else if (e.clientX <= 0) {
              l = 0 - disX
            } else {
              l = e.clientX - disX
            }
            if (e.clientY >= bh) {
              t = bh - disY
            } else if (e.clientY <= 0) {
              t = 0 - disY
            } else {
              t = e.clientY - disY
            }
            requestAnimationFrame(() => {
              oDiv.style.left = l + 'px'
              oDiv.style.top = t + 'px'
            })
          }, 66)
          document.onmouseup = function (e) {
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      }
    )
    // 权限
    Vue.directive('permission', (el, binding) => {
      const res = store.getters['permission/permissionRes'](binding.value)
      if (res === 0) {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }
    })
  }
}
