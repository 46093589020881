<template>
<div class="nodata-row">
  <div class="nodata-wraper">
     <img draggable="false" class="nodata" src="~@/assets/empty.png"/>
     <span class="nodata nodata-txt">{{label}}</span>
  </div>
  </div>
</template>
<script>
export default {
  name: 'no-data',
  props: {
    label: {
      type: String,
      default: '这里暂无数据哦~'
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@/globals.scss';
  .nodata-row{
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .nodata-txt {
    color: #333;
    font-size: 16px;
  }
  .nodata-wraper{
    display: flex;
    flex-direction: column;
    align-items: center;
    span{
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
    }
    img{
      max-width: 120px;
      max-height: 120px;
    }
  }
</style>
