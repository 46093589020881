import axios from '@/common/request/request'
import { getUserId } from '@/storage/viewStorage'
// 用户积积分列表
export const listDetail = (userId, params) => {
  return axios.request({
    method: 'get',
    url: 'system/points/listDetail/' + userId,
    params: params
  })
}

export const rankInfo = (id) => {
  const userId = getUserId()
  return axios.request({
    method: 'get',
    url: 'system/points/rank/' + (id || userId)
  })
}

export const userPoints = (userId) => {
  return axios.request({
    method: 'get',
    url: 'system/points/' + userId
  })
}
