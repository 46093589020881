import Axios from './axios'
import config from '@/config'

const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro

const option = {
  baseUrl: baseUrl
}

const axios = new Axios(option)

export default axios
