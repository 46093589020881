import Storage from './index'
const storage = new Storage(window.localStorage)
const CookieKey = 'User-Token'
const storageConfig = {
  CHAPTERID: true, // 基础课章节id
  courseName: true, // 基础课课程名称
  COURSEPARAMS: true, // 基础课请求参数
  recvprotcal: true, // 是否已阅读协议
  rememberme: true, // 记住账号
  username: true, // 保存的用户账号
  sideMenuStatus: true, // 记录基础课用户点击的侧边栏位置
  userId: true,
  isBaseMask: false,
  isNoBaseMask: true,
  SCHLCHAPTERID: false,
  COURSEID: false, // 基础课课程id
  CLUBCHAPTERID: false,
  TimeTableId: false,
  SCHLCOURSEID: false,
  SCHLCOURSEPARAMS: false,
  schlSideMenuStatus: false,
  CLUBCOURSEID: false,
  token: false,
  USER_INFO: false,
  machineIden: true,
  isclubflag: false, // 是否是社团模式
  CLUBCLASSID: false,
  CurrentFile: false,
  isCacheType: false,
  castUrl: false,
  castAdminUrl: false,
  comboConfigType: false,
  route: false
}
// 保存配置中的值
export const setItem = (k, v) => {
  // eslint-disable-next-line no-prototype-builtins
  // 不管可不可以都支持存储,都是electron
  let value = ''
  if (typeof v !== 'string') {
    value = JSON.stringify(v)
  } else {
    value = v
  }
  storage.setItem(k, value)
}
// 获取某个值
export const getItem = (k) => {
  return storage.getItem(k)
}
// 删除某个值
export const removeItem = (k) => {
  storage.removeItem(k)
}
// 临时保存
export const setTempItem = (k, v) => {
  storage.setItem(k, v)
}
// 退出登录后注销的本地存储用户信息
export const removeUserItem = () => {
  const expires = new Date()
  expires.setTime(expires.getTime() + 1000)
  document.cookie = `${CookieKey}='';expires=Session;domain=.zksr.net`
  const keys = Object.keys(storageConfig)
  keys.forEach(val => {
    if (!storageConfig[val]) {
      storage.removeItem(val)
    }
  })
}
// 获取基础课课程id
export const getCourseId = () => {
  return getItem('COURSEID')
}
// 设置基础课id
export const setCourseId = (val) => {
  setItem('COURSEID', val)
}
// 清除全部
export const clear = () => {
  storage.clear()
}
// 保存用户信息
export const setUserInfoCookie = (userInfo) => {
  setItem('USER_INFO', userInfo)
}
// 获取用户信息
export const getUserInfoCookie = () => {
  const userInfoStr = getItem('USER_INFO')
  const userInfo = (userInfoStr && typeof userInfoStr === 'string') ? JSON.parse(userInfoStr) : {}
  return userInfo
}
// 获取用户id
export const getUserId = () => {
  return getUserInfoCookie().userId
}

// 保存用户token
export const setTokenCookie = (token) => {
  setItem('token', token)
  const expires = new Date()
  expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000)
  document.cookie = `${CookieKey}=${token};expires=${expires.toGMTString()};domain=.zksr.net`
}
// 获取用户token
export const getToken = () => {
  return getItem('token')
}
// 保存用户权限路由
export const setRoutes = (route) => {
  const _route = JSON.stringify(route)
  setItem('route', _route)
}
// 获取路由
export const getRoutes = () => {
  let r = getItem('route')
  console.log(120, r)
  if (r) {
    console.log(554)
    r = JSON.parse(r)
  }
  console.log(r)
  return r
}
// 删除用户路由
export const delRoutes = () => {
  removeItem('route')
}

// 保存用户机器id
export const setMachineIden = (machineIden) => {
  // console.log(11)
  storage.setItem('machineIden', machineIden)
}
// 获取用户机器id
export const getMachineIden = () => {
  return storage.getItem('machineIden')
}
