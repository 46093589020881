// 定义用户基础信息
export default {
  state: {
    rightpanelheight: 840,
    socketisok: false,
    vTimer: 0,
    targetQuestionList: []
  },
  mutations: {
    updaterightpanelheight (state, height) {
      state.rightpanelheight = height
    },
    updatersocketisok (state, socketisok) {
      state.socketisok = socketisok
    },
    updateTimer (state, timer) {
      state.vTimer = timer
    },
    setQuestionList (state, list) {
      state.targetQuestionList = list
    }
  },
  actions: {
    setSystemTimer ({ commit }, { fn, interval }) {
      if (this.vTimer) clearInterval(this.vTimer)
      const timer = setInterval(fn, interval)
      commit('updateTimer', timer)
    },
    clearSystemTimer ({ commit }) {
      if (this.vTimer) clearInterval(this.vTimer)
      commit('updateTimer', 0)
    }
  },
  getters: {
    targetQuestionList: state => state.targetQuestionList
  }
}
