import { Message, Modal } from 'view-design'
import { loginout } from '@/api/login'
import route from '@/router'
import axios from 'axios'
import { getToken } from '@/storage/viewStorage'

// function getRequesStrIdFn (config) {
//   const strid = `${config.url}${config.method}${config.data ? JSON.stringify(config.data) : ''}${config.params ? JSON.stringify(config.params) : ''}`
//   return strid
// }

class Axios {
  constructor (option) {
    // console.log('constructor:', option)
    this.baseURL = option.baseUrl
    // 请求队列，防止重复请求
    this.requestArr = []
  }

  interceptors (instance) {
    instance.interceptors.request.use(config => {
      const token = getToken()
      // console.log(token)
      // config.headers['Access-Token'] = token
      // config.headers['Authorization'] = token || ''
      config.headers.Authorization = token || ''
      // const url = config.url
      // // 匹配path中的服务器域名
      // // const urlReg = new RegExp('[a-zA-z]+://[^\s]*')
      // // 如果匹配到域名就使用传入的path，否则使用配置的服务器地址
      // const isHttp = url.indexOf('aliyun/oos') >= 0
      // // 如果请求是http路径则重置baseURL为''
      // config.baseURL = isHttp ? '' : config.baseURL
      // console.log('interceptors request:', config)
      // const str = getRequesStrIdFn(config)
      // config.originUrl = config.url
      // if (!this.requestArr.includes(str)) {
      //   config.requestStr = str
      //   this.requestArr.push(str)
      //   return config
      // } else {
      //   return Promise.reject(new Error(`重复请求：${config.url}`))
      // }
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.requestArr = this.requestArr.filter((item) => item !== res.config.requestStr)
      if (res.data.code === 403 && res.config.originUrl !== 'system/receive/msgnum') {
        Modal.success({
          title: '提示',
          content: '登录账号已过期，请重新登录。',
          okText: '确定',
          onOk: () => {
            setTimeout(() => {
              loginout().then(() => {
                // this.$store.commit('user/updateFullScreenStatus', false)
                route.push('/login')
              })
            }, 200)
          }
        })
      }
      if (typeof res.data === 'object' && Array.isArray(res.data)) {
        return res.data
      }
      if (res.data.code === 500 && (res.data.msg === '该试卷尚未提交答案')) {
        return
      }
      if (res.data.code === 500 && (res.data.msg === '未找到点赞记录' || res.data.msg === '您已经点赞过此资源')) {
        return {
          msg: '取消点赞或者点赞失败'
        }
      }
      // 操作失败
      if (res.data.code === 500 || res.data.code === 505) {
        Message.warning(res.data.msg || '操作失败')
        // return
      }
      // 系统内部错误
      if (res.data.code === 10002) {
        // Message.error('系统内部错误：查询失败！')
      }
      // 字段校验不通过
      if (res.data.code === 10003) {
        // Message.warning(res.data.msg)
      }

      if (res.data.code === 200 || res.data.code === 999) {
        const { data, status } = res
        return { data, status }
      } else {
        return Promise.reject(res.msg || res.data.msg || '请求错误')
      }
    }, error => {
      if (error.toString() === 'Error: Network Error') {
        this.requestArr = []
        return
      }
      let errorInfo = error.response
      if (!errorInfo && errorInfo !== undefined) {
        const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
        errorInfo = {
          statusText,
          status,
          request: { responseURL: config.url }
        }
      }
      return Promise.reject(error)
    })
  }

  request (option) {
    // 默认post请求
    if (!option.method) option.method = 'post'

    // baseUrl
    option.baseURL = this.baseURL

    // axios实例
    const instance = axios.create()

    // 请求和响应拦截
    this.interceptors(instance)

    // 返回实例结果
    return instance(option)
  }
}

export default Axios
