
/**
 *
 * @param {时间} date
 * @param {yyyy-MM-dd hh:mm:ss} fmt
 */
export const dateformat = function (date, fmt) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}

/*
* 返回格式化后的时间
* timeStamp：时间戳
* startType：要转换的类型<year:yyyy-MM-dd hh:mm:ss|yearDate:yyyy-MM-dd|monthDate:MM-dd|MM-dd hh:mm>
*/
export const getStandardDate = (timeStamp, startType) => {
  const dt = new Date(timeStamp)
  const year = dt.getFullYear()
  const month = (dt.getMonth() + 1 + '').padStart(2, '0')
  const date = (dt.getDate() + '').padStart(2, '0')

  const hours = (dt.getHours() + '').padStart(2, '0')
  const minutes = (dt.getMinutes() + '').padStart(2, '0')
  const second = (dt.getSeconds() + '').padStart(2, '0')
  let resStr = ''
  if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
  else if (startType === 'yearDate') resStr = year + '年' + month + '月' + date + '日'
  else if (startType === 'monthDate') resStr = month + '月' + date + '日'
  else resStr = month + '月' + date + '日' + ' ' + hours + ':' + minutes
  return resStr
}

// 获取资源的来源和文件详细类型
export const getFileSourceAndType = (item) => {
  // source：1.标准资源，2.云资源，3.个人资源
  const source = item.source
  // contentType：1.word，2.ppt，3.excel，4.图片，5.图片集, 6.视频, 7.视频集, 8.3D, 9.pdf, 14. ppt多媒体
  const extName = item.extName
  const contentType = item.contentType
  // 资源类型icon
  let sourceIcon = ''
  // 文件类型
  let fileIcon = ''
  // 文件类型icon
  let typeIcon = ''
  // 资源类型
  switch (source) {
    case '1' :
      sourceIcon = require('@/assets/resource/arrow-standard.png')
      break
    case '2' :
      sourceIcon = require('@/assets/resource/arrow-cloud.png')
      break
    case '3' :
      sourceIcon = require('@/assets/resource/arrow-person.png')
      break
    default:
      break
  }
  // 资源类型Icon
  switch (contentType) {
    case '1':
      typeIcon = require('@/assets/class/word.png')
      break
    case '2':
      typeIcon = require('@/assets/class/ppt.png')
      break
    case '3':
      typeIcon = require('@/assets/class/excel.png')
      break
    case '9':
      typeIcon = require('@/assets/class/pdf.png')
      break
    case '14':
      typeIcon = require('@/assets/class/ppt.png')
      break
    case '4':
      typeIcon = require('@/assets/class/img.png')
      break
    case '5':
      typeIcon = require('@/assets/class/imgs.png')
      break
    case '6':
    case '7':
      typeIcon = require('@/assets/class/video.png')
      break
    case '12':
    case '13':
      typeIcon = require('@/assets/class/audio.png')
      break
    case '8':
    case '10':
    case '11':
      typeIcon = require('@/assets/class/3d.png')
      break
    case '16':
      typeIcon = require('@/assets/class/text_icon.png')
      break
    case '17':
      typeIcon = require('@/assets/class/link_icon.png')
      break
    default:
      typeIcon = require('@/assets/class/video.png')
      break
  }
  switch (contentType) {
    // 1.word,2.ppt,3.excel,9.pdf,14.PPT多媒体
    case '1':
      fileIcon = 'word'
      break
    case '2':
      fileIcon = 'ppt'
      break
    case '3':
      fileIcon = 'excel'
      break
    case '9':
      fileIcon = 'pdf'
      break
    case '14':
      fileIcon = 'PPT'
      break
    case '4':
      fileIcon = '图片'
      break
    case '5':
      fileIcon = '多图'
      break
    // 6.视频,7.视频集
    case '6':
    case '7':
      fileIcon = '视频'
      break
    // 12.mp3,13.m4a
    case '12':
    case '13':
      fileIcon = '音频'
      break
    // 8.3D-fbx模型,10.3D-mr模型,11.3D-web模型
    case '8':
    case '10':
    case '11':
      fileIcon = '3D'
      break
    default:
      fileIcon = extName
      break
  }
  return {
    source: sourceIcon,
    contentType: fileIcon,
    typeIcon
  }
}
// 获取资源的来源和文件粗略类型
export const getFileSourceAndSkimType = (item) => {
  // source：1.标准资源，2.云资源，3.个人资源
  const source = item.source
  // contentType：1.word，2.ppt，3.excel，4.图片，5.图片集, 6.视频, 7.视频集, 8.3D, 9.pdf, 14. ppt多媒体
  const extName = item.extName
  const contentType = item.contentType
  // 资源类型icon
  let sourceIcon = ''
  // 文件类型icon
  let fileIcon = ''
  // 资源类型
  switch (source) {
    case '1' :
      sourceIcon = require('@/assets/resource/arrow-standard.png')
      break
    case '2' :
      sourceIcon = require('@/assets/resource/arrow-cloud.png')
      break
    case '3' :
      sourceIcon = require('@/assets/resource/arrow-person.png')
      break
    case '4' :
      sourceIcon = require('@/assets/resource/school_type_icon.png')
      break
    default:
      break
  }
  switch (contentType) {
    // 1.word,2.ppt,3.excel,9.pdf,14.PPT多媒体
    case '1':
    case '2':
    case '3':
    case '9':
    case '14':
      fileIcon = '文档'
      break
    case '4':
      fileIcon = '图片'
      break
    case '5':
      fileIcon = '多图'
      break
    // 6.视频,7.视频集
    case '6':
    case '7':
      fileIcon = '视频'
      break
    // 12.mp3,13.m4a
    case '12':
    case '13':
      fileIcon = '音频'
      break
    // 8.3D-fbx模型,10.3D-mr模型,11.3D-web模型,5, 3D-exe模型
    case '8':
    case '10':
    case '11':
    case '15':
      fileIcon = '3D'
      break
    case '16':
      fileIcon = '富文本'
      break
    case '17':
      fileIcon = '外链'
      break
    default:
      fileIcon = extName
      break
  }
  return {
    source: sourceIcon,
    contentType: fileIcon
  }
}

// 获取文件下载保存的父目录
export const getParentDir = (type) => {
  let dir = ''
  switch (type) {
    case '1':
    case 'doc':
    case 'docx':
      dir = 'word'
      break
    case '2':
    case 'ppt':
    case 'pptx':
      dir = 'ppt'
      break
    case '3':
    case 'xls':
    case 'xlsx':
      dir = 'excel'
      break
    case '4':
    case 'jpg':
    case 'png':
      dir = 'image'
      break
    case '5':
      dir = 'images'
      break
    case '6':
    case 'mp4':
      dir = 'video'
      break
    case '7':
      dir = 'videos'
      break
    case '15':
      dir = '3d'
      break
    case '9':
    case 'pdf':
      dir = 'pdf'
      break
    case '10':
      dir = 'mr3d'
      break
    case '8':
      dir = '3ddefault'
      break
    case '11':
      dir = '3dweb'
      break
    case '12':
    case 'mp3':
      dir = 'mp3'
      break
    case '13':
    case 'm4a':
      dir = 'm4a'
      break
    case '14':
      dir = 'multimedia_ppt'
      break
    default:
      break
  }
  return dir
}

// buffer转文件类型
export const bufferToFile = file => {
  /*
    file: object
      localPath: 文件在本地的绝对路径
      resName: 文件的原始文件名称
  */
  const filename = file.localPath && file.localPath.split('\\\\')[file.localPath.split('\\\\').length - 1]
  return new Promise((resolve) => {
    console.log(filename)
  })
}

// 节流
export const throttle = {
  timer: null,
  process: function (fn, delay) {
    if (this.timer) clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      fn()
    }, delay)
  }
}

// 防抖
export const debounce = (fn, delay) => {
  let timer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

export const throttlePlus = (fn, delay = 200) => {
  let timer = null
  let firstTime = true

  return (...args) => {
    if (firstTime) {
      // 第一次加载
      fn.apply(this, args)
      firstTime = false
      return firstTime
    }
    if (timer) {
      // 定时器正在执行中，跳过
      return
    }
    timer = setTimeout(() => {
      clearTimeout(timer)
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}

// 通过一个阈值来阻止后续代码继续执行
export const forbidDoubleClick = {
  timestamp: '',
  statue: false,
  threshold: function (delay) {
    const currentTime = new Date().getTime()
    if (this.timestamp !== '' || currentTime - this.timestamp < delay) {
      this.statue = false
    } else {
      this.timestamp = currentTime
      this.statue = true
      setTimeout(() => {
        this.timestamp = ''
      }, delay)
    }
    // console.log('diff ms', currentTime - this.timestamp, this.statue, delay)
    return this.statue
  }
}

/**
 * 空值判断
 * @param _
 */

export function isEmpty (_) {
  if (typeof _ === 'string') {
    return _.trim() === ''
  } else if (_ === null || _ === undefined) {
    return true
  } else if (typeof _ === 'number') {
    return _ === 0
  } else if (typeof _ === 'object') {
    if (Object.keys(_).length === 0) {
      return true
    } else {
      for (const k in _) {
        if (!isEmpty(_[k])) return false
      }
      return true
    }
  }
}
