// 用户信息
const toolConfig = {
  namespaced: true,
  state: {
    choiceItems: [
      { toolId: 1, img: require('@/assets/tools/line-big.png'), title: '单选' },
      { toolId: 2, img: require('@/assets/tools/line-big.png'), title: '多选' }
    ],
    dragItems: [
      { appId: 'drag', templete: 1, img: require('@/assets/tools/drag-word.png'), itemId: 1, title: '文字类' },
      { appId: 'drag', templete: 2, img: require('@/assets/tools/drag-pic.png'), itemId: 1, title: '图片类' }
    ],
    choseItems: [
      { toolId: 1, img: require('@/assets/tools/select-single.png'), title: '单选' },
      { toolId: 2, img: require('@/assets/tools/select.png'), title: '多选' }
    ],
    mindMapItems: [
      { toolId: 1, img: require('@/assets/tools/mindmap-template.png'), title: '思维导图' }
    ]
  },
  getters: {
    getItemBg: state => val => {
      const imgConfig = {
        choice: {
          img: require('@/assets/tools/select-single.png')
        },
        drag: {
          img: require('@/assets/tools/drag.png')
        },
        line: {
          img: require('@/assets/tools/line.png')
        },
        mindMap: {
          img: require('@/assets/tools/mindmap-template.png')
        }
      }
      const items = imgConfig[val.appId]
      return items || {
        img: 'https://oos-cn.ctyunapi.cn/test-public-bucket/cover.jpg'
      }
    }
  }
}

export default toolConfig
