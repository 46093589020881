// 年级
const getGradeFn = (str) => {
  if (!str) return ''
  const grades = ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级']
  const res = str.split('|').filter(val => val).sort((a, b) => a - b).map(val => {
    return grades[Number(val) - 1]
  })
  return res.join(', ')
}
// 格式话时间
const formateTime = function (str, fmt) {
  let date
  if (str instanceof Date) {
    date = str
  } else if (typeof str === 'string' || typeof str === 'number') {
    date = new Date(str)
  } else {
    return ''
  }
  if (date.toString() === 'Invalid Date') {
    return ''
  }
  if (!fmt) {
    fmt = 'yyyy-MM-dd'
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}
// 格式化手机号码
const mobile = (o) => {
  if (!o) {
    return ''
  } else {
    return o.substr(0, 4) + '****' + o.substr(8, 11)
  }
}
// 距离天数
const getDayDistanceFn = (time) => {
  const current = Date.now()
  const target = new Date(time).getTime()
  return Math.floor((current - target) / (24 * 60 * 60 * 1000))
}
// 截取四个字加...
const sliceFourTxt = (str, num = 4) => {
  return str.length > num ? str.slice(0, num) + '...' : str
}
const formatHtml = (html, max) => {
  let res = ''
  const str = html && html.replace(/<\/?.+?\/?>/g, '')
  res = str && str.replace(/&nbsp;/g, '')
  if (max) {
    res = str.slice(0, max)
    if (str.length > max) {
      res = res + '...'
    }
  }
  return res
}

export default {
  getGradeFn,
  formateTime,
  mobile,
  getDayDistanceFn,
  sliceFourTxt,
  formatHtml
}
