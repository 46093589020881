
import { setCourseId, setUserInfoCookie, setTokenCookie, removeUserItem } from '@/storage/viewStorage'
import { authorize, register, getUserBindSchlApi, codeLoginApi, loginBySsoApi } from '@/api/login'
import { ssoLoginApi } from '@/api/system'
import { getInfo, getTeachInfo } from '@/api/myinfo'
import { initOOsClient } from '@/api/ctyun'
import { getNewMsgNum, recordNumberApi, recordTimeApi } from '@/api/message'
import { updateAccountContext } from '@/common/event-bus/ipc-bridge'
import { getSysConfigApi } from '@/api/dict'

// 用户登录后数据统一处理
function userInfoHandle (userInfo, commit, resolve) {
  const userId = userInfo.user_id
  const token = userInfo.access_token
  const obj = { user_id: userId, id: token }
  commit('update', obj)
  commit('userFirstLogin', userInfo.firstLogin)
  commit('setUserRoles', userInfo.userRoles && userInfo.userRoles.length && userInfo.userRoles[0])
  updateAccountContext(userInfo)
  setTokenCookie(token)
  initOOsClient()
  getSysConfigFn(commit)
  recordNumberApi({ event: 'login' })
  recordTimeApi({ event: 'login', isEnd: false })
  resolve(userId)
}

function getSysConfigFn (commit) {
  getSysConfigApi('user_download_switch').then(res => {
    commit('setDownloadFlag', res.data.msg || '1')
  })
}
const defaultuser = {
  id: '',
  classStatus: false,
  msgnum: 0,
  userId: '',
  nickName: '匿名用户',
  avatar: '',
  avatarUrl: '',
  province: '',
  city: '',
  county: '',
  schlId: undefined,
  personalSchlId: '',
  courseId: '',
  remark: '',
  addr: '',
  sex: '',
  birthday: '',
  firstLogin: '',
  isFullScreen: false,
  // 社团功能 1: 开启 0：关闭
  rightFlag: '0',
  // 社团是否自动审核 0：手动 1：自动
  autoAudit: '0',
  // 区域id
  areaId: '0',
  // 区域logo
  areaLogo: '',
  // 机构老师学校id
  schoolId: '',
  userRole: {},
  downloadFlag: '1'
}

// 定义用户基础信息
const state = Object.assign({}, defaultuser)

const mutations = {
  // 更新用户信息
  update (state, data) {
    for (const i in data) {
      // 解决用户注册新账户昵称为他人老昵称的bug
      if (data[i] === null) {
        data[i] = ''
      }
      state[i] = data[i]
    }
    if (data.user_id) {
      state.id = data.user_id
    }
    // console.log('avatar', data.avatar)
    setUserInfoCookie(state)
  },
  userFirstLogin (state, data) {
    state.firstLogin = data
  },
  // 更新上下课信息
  updateClassStatus (state, data) {
    state.classStatus = data
    setUserInfoCookie(state)
  },
  // 更新全屏非全屏装填
  updateFullScreenStatus (state, data) {
    state.isFullScreen = data
  },
  // 设置角色
  setUserRoles (state, data) {
    state.userRole = data
    setUserInfoCookie(state)
  },
  setDownloadFlag (state, data) {
    state.downloadFlag = data
    setUserInfoCookie(state)
  }
}
function getstep () {
  let a = 0
  if (!state.nickName || !(state.schlId || state.personalSchlId || state.personalSchlName)) {
    a = 1
  } else if (!state.courseId) {
    a = 2
  }
  return a
}
const actions = {
  login ({ commit }, param) {
    return new Promise((resolve, reject) => {
      param.project = 'BSK'
      authorize(param).then(res => {
        userInfoHandle(res.data.data, commit, resolve)
      }).catch(err => {
        console.log(err)
        resolve(undefined)
      })
    })
  },
  ssoAuth ({ commit }, param) {
    return new Promise((resolve, reject) => {
      loginBySsoApi().then(res => {
        userInfoHandle(res.data.data, commit, resolve)
      }).catch(err => {
        console.log(err)
        resolve(undefined)
      })
    })
  },
  codeLoginFn ({ commit }, param) {
    return new Promise((resolve, reject) => {
      codeLoginApi(param).then(res => {
        userInfoHandle(res.data.data, commit, resolve)
      }).catch(err => {
        console.log(err)
        resolve(undefined)
      })
    })
  },
  ssoLoginFn ({ commit }, param) {
    return new Promise((resolve, reject) => {
      param.project = 'BSK'
      ssoLoginApi(param).then(res => {
        userInfoHandle(res.data.data, commit, resolve)
      }).catch(err => {
        console.log(err)
        resolve(undefined)
      })
    })
  },
  logout ({ commit }) {
    return new Promise(resolve => {
      commit('update', defaultuser)
      commit('setUserRoles', '')
      removeUserItem()
      resolve()
    })
  },
  closeWindow () {
    window.opener = null
    window.open('about:blank', '_self')
    window.close()
  },
  // 微信扫码登录
  wxLogin ({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      userInfoHandle(userInfo, commit, resolve)
    })
  },
  // 获取教师是否需要绑定学校
  getUserBindSchl ({ commit }, bindMobile) {
    return new Promise((resolve, reject) => {
      getUserBindSchlApi(bindMobile).then(res => {
        resolve((res.data && res.data.data) || [])
      })
    })
  },
  // 登录获取用户信息
  getInfo ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      getInfo(userId).then(res => {
        const data = res.data.data
        if (!data.courseId) {
          data.courseId = ''
        }
        if (!data.schlId && data.personalSchlId) {
          // data.schlId = data.personalSchlId // 更新接口会混淆数据
          data.schlName = data.personalSchlName
        }
        // 存储默认的courseID
        setCourseId(data.courseId)
        getTeachInfo(userId).then(res => {
          if (res.data && res.data.data) {
            const teachData = res.data.data
            data.rightFlag = teachData.rightFlag
            data.autoAudit = teachData.autoAudit
            data.areaId = teachData.areaId
            data.schlName = teachData.schoolName
            data.areaLogo = teachData.logoUrl
            data.schoolId = ''
            commit('update', data)
            resolve({
              userInfo: state,
              step: getstep()
            })
          } else {
            commit('update', data)
            resolve({
              userInfo: state,
              step: getstep()
            })
          }
        })
      })
    })
  },
  refreshMsgNum ({ commit }, param) {
    return new Promise((resolve, reject) => {
      getNewMsgNum({}).then(res => {
        const num = res.data.data
        commit('update', { msgnum: num })
        resolve(num)
      })
    })
  },
  refresh ({ commit }, param) {
    return new Promise((resolve, reject) => {
      getInfo(state.id).then(res => {
        commit('update', res.data.data)
      })
    })
  },
  step ({ commit }) {
    return new Promise((resolve, reject) => {
      resolve(getstep())
    })
  },
  // 更新用户默认教学资源类型 2：苏教版，3：教科版
  updateVerFn ({ commit }, userInfo) {
    commit('update', userInfo)
  },
  registFn ({ commit }, param) {
    return new Promise((resolve, reject) => {
      param.project = 'BSK'
      register(param).then(res => {
        const userId = res.data.data.user_id
        const token = res.data.data.access_token
        const obj = { user_id: userId, id: token }
        setTokenCookie(token)
        setTimeout(() => {
          commit('update', obj)
          commit('userFirstLogin', res.data.data.firstLogin)
          initOOsClient()
          getInfo(userId).then(res => {
            if (res.data && res.data.data) {
              const data = res.data.data
              if (!data.courseId) {
                data.courseId = ''
              }
              // 存储默认的courseID
              setCourseId(data.courseId)
              // 存储默认的教材名称
              // setCookie('COURSENAME', data.courseName)
              commit('update', data)
              resolve(data, 1)
            } else {
              resolve(1)
            }
          })
        }, 100)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
