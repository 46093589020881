function notsurportfunc () {
  console.error('该功能尚不支持,请确保是否在客户端环境下登录', arguments)
}
const defaultApi = {
  invoke: notsurportfunc, send: notsurportfunc, once: notsurportfunc, on: notsurportfunc, removeListener: notsurportfunc
}
let ipcRenderer = defaultApi
if (typeof window.ipcApi === 'object') {
  ipcRenderer = window.ipcApi
}
export default ipcRenderer
