<template>
  <!-- web端 -->
  <div class="page-wrap">
    <div class="mask" v-if="extName === 'mp3' || extName === 'm4a' || extName === '12' || extName === '13'" :class="[isMax ? 'maxVideo' : 'video']">
      <img src="http://oos-cn.ctyunapi.cn/public-bucket/play.gif" alt="">
    </div>
    <div class="video-wrap" :class="[isMax ? 'maxVideo' : 'video']">
      <!-- <video
        class="video-dom"
        :src="videoSrc"
        width="800"
        height="600"
        autoplay="autoplay"
        controls
        playsinline
        webkit-playsinline
        controlslist="nodownload noremoteplayback"
        id="videoDom"
      ></video> -->
      <video-comp v-if="videoSrc" :videoSrc="videoSrc" :isM3U8="isM3U8"></video-comp>
    </div>
  </div>
</template>

<script>
import VideoComp from '@/component/videoComp/index.vue'

export default {
  components: {
    VideoComp
  },
  props: {
    videoSrc: {
      type: String,
      default: ''
    },
    extName: {
      type: String,
      default: ''
    },
    isM3U8: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMax: false,
      source: ''
    }
  },
  created () {
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
    this.noRate = localStorage.getItem(this.resId + 'isMax') || this.source === '3' || this.source === 'undefined' || this.source === '4'
    localStorage.removeItem(this.resId + 'isMax')
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
  },
  methods: {
    stopVideoFn () {
      const dom = document.getElementById('videoDom')
      const status = dom.paused
      setTimeout(() => {
        if (status) {
          dom.play()
        } else {
          dom.pause()
        }
      }, 66)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  position: relative;
  width: 100%;
  height: 475PX;
  /*background: #000;*/
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: calc(100% - 32px);
    }
  }

  .video {
      width: 100%;
      // height: calc(100% - 150px);
    }

    .maxVideo {
      width: 100%;
      height: 100%;
    }
    .video-dom {
      width: 100%;
      height: 475px;
      background: black;
    }
}
</style>
