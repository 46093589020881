<template>
  <div class="ctyun">
    <div class="resultlist" v-if="showUploadList && resultList.length>0">
      <div v-for="(item,index) in resultList" :key="index" class="upload-drag">
        <img draggable="false" :src="item" class="iconwraper">
        <div class="btn-delect"  @click="deleteit(index)">
          <img  draggable="false" src="~@/assets/icon-delete.png"  />
        </div>
     </div>
    </div>
    <span class="actionlist" v-if="enableupload">
      <slot>
        <div class="upload-drag">
        <div class="iconwraper">
          <img draggable="false" src="~@/assets/plus-l.png" style="width: 24px" />
        </div>
        </div>
      </slot>
      <input ref="filElem" type="file" class="file-upload" accept="image/*" multiple="multiple" v-if="multiple" @change="uploadit($event)" value="点击这里上传" />
      <input ref="filElem" type="file" class="file-upload" accept="image/*" v-if="!multiple" @change="uploadit($event)" value="点击这里上传" />
    </span>
    </div>
</template>
<script>
import { putSmallObject, putPublicSmallObject, initOOsClient } from '@/api/ctyun'
// import {uploadcallback} from '@/api/resources'
export default {
  name: 'ctyun-img',
  props: {
    showUploadList: {
      Type: Boolean,
      default: true
    },
    onSuccess: {
      Type: Function,
      require: true
    },
    enableupload: {
      Type: Boolean,
      default: true
    },
    multiple: {
      Type: Boolean,
      default: false
    },
    resultList: {
      type: Array,
      default: () => []
    },
    limit: Number,
    isDefault: {
      type: Boolean,
      default: true
    },
    baseUrl: {
      type: String,
      default: ''
    },
    bucketType: {
      type: Number,
      default: 2
    }
    // removeItem: []
  },
  created () {
    initOOsClient()
  },
  data () {
    return {
      src: ''
    }
  },
  methods: {
    deleteaall () {
      // eslint-disable-next-line vue/no-mutating-props
      this.resultList.splice(0, this.resultList.length)
    },
    deleteit (partUrl) {
      const index = this.resultList.findIndex(item => item.includes(partUrl))
      // eslint-disable-next-line vue/no-mutating-props
      this.resultList.splice(index, 1)
      this.$emit('delItemFn', this.resultList)
    },
    uploadit (e) {
      const isAllImage = Array.from(e.target.files).every(f => f.type.indexOf('image') > -1)
      // bug修改： 如果上传存在非图片格式文件，提示上传失败
      if (!isAllImage) {
        this.$Message.warning('上传失败，请选择图片上传')
        return
      }
      const files = this.$refs.filElem.files
      if (this.limit && (this.limit < files.length || this.limit < (files.length + this.resultList.length))) {
        this.$Message.warning(`最多上传${this.limit}张图片`)
        return
      }
      const uploadTasks = []
      for (let i = 0; i < files.length; i++) {
        uploadTasks.push(this.uploadOne(files[i]))
      }
      // 上传完成后清除文件
      Promise.all(uploadTasks).then(res => {
        e.target.value = ''
      }).catch(err => {
        console.log(err)
      })
    },
    uploadOne (file) {
      if (this.isDefault) {
        putSmallObject(file, { bucketType: this.bucketType, baseUrl: this.baseUrl }).then(res => {
          const url = res.onlineUrl || res.fileUrl
          this.src = url
          // eslint-disable-next-line vue/no-mutating-props
          this.resultList.push(url)
          res.data = { url: url, key: res.key, name: file.name, size: file.size }
          // uploadcallback(res.key)
          this.onSuccess(res, file, this.resultList)
          // getSignedUrl(res.key).then(data => {
          // }).catch(err => {
          //   this.$emit('error', err)
          // })
        }).catch(err => {
          this.$emit('error', err)
        })
      } else {
        putPublicSmallObject(file, { baseUrl: this.baseUrl }).then(res => {
          const fileUrl = res.onlineUrl || res.fileUrl
          this.src = fileUrl
          // eslint-disable-next-line vue/no-mutating-props
          this.resultList.push(fileUrl)
          res.data = { url: fileUrl, key: res.key, name: file.name, size: file.size }
          this.onSuccess(res, file, this.resultList)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ctyun{
  position: relative;
  display: flex;
  flex-direction: row;
  .file-upload{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
  }
  .iconwraper{
  width:120px;
  height:120px;
  line-height:120px;
  display:flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 0 2px #ccc;

}
.resultlist{
  display: flex;
  flex-flow: row wrap;
  justify-content:  flex-start;
}
.actionlist{
  position: relative;
}
.upload-drag {
    background: #fff;
    border: 1px dashed #ccc;
    border-radius: 8px;
    margin:0 5px 5px 0;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    -webkit-transition: border-color .2s ease;
    transition: border-color .2s ease;
    .btn-delect{
      background: white;
      position:absolute;
      border-radius: 50%;
      right: 0;
      top: 0;
      z-index: 99;
      display: flex;
      justify-content: center;
      align-content: center;
      img{
        width: 20px;
        height: 20px;
      }
    }
}
}
</style>
