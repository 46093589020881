<template>
  <div class="imgs-player">
    <div class="container">
        <Row v-show="!isLoad" class="img-mask">
            <Col class="demo-spin-col" span="24">
                <Spin fix>
                    <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                    <div>Loading</div>
                </Spin>
            </Col>
        </Row>
        <div class="box" @mouseup="boxUp">
            <div class="left">
              <div v-if="boxArr.length" class="img-name">
                <p>{{boxArr[imgIndex].filename || boxArr[imgIndex].graphFilename}}</p>
                <div>(第<span>{{imgIndex + 1}}</span>/<span>{{imgArr.length}}</span>)</div>
              </div>
              <div class="img-box" id="imgbox">
                <img draggable="false" v-drag id="mainImg" class="main-img" :src="mainImg" alt="">
                <img id="mainImg2" style="z-index:-1111;left:10000em" class="main-img" :src="mainImg" alt="">
              </div>
              <div class="slider-box">
                <div @click="o2o" class="o2o"></div>
                <div @click="prev" class="prev"></div>
                <div @click="next" class="next turn"></div>
                <div class="max" id="image-max"></div>
                <div class="min" id="image-min"></div>
              </div>
            </div>
            <div v-if="imgArr.length" class="right">
              <div class="wrapper">
                <ul class="img-mini" v-if="$route.query.type === 'online'">
                  <li
                    :class="{'on': index == imgIndex}"
                    @click="chooseImg(item, index)"
                    v-for="(item, index) in imgArr"
                    :key="index"
                    :style="{
                      backgroundImage: 'url(' + item +')'
                    }"
                  >
                  </li>
                </ul>
                <ul class="img-mini" v-else>
                  <li
                    :class="{'on': index == imgIndex}"
                    @click="chooseImg(item, index)"
                    v-for="(item, index) in imgArr"
                    :key="index"
                  >
                    <img :src="item">
                  </li>
                </ul>
              </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import BScroll from 'better-scroll'
export default {
  props: {
    arr: {
      type: Array,
      default: () => []
    },
    resId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      per: 0,
      isLoad: false,
      base: '',
      bscroll: null,
      imgIndex: 0,
      imgName: '',
      total: '',
      canDrag: false,
      x0: 0,
      y0: 0,
      x1: 0,
      y1: 0,
      style: null,
      mainImg: '',
      longTimer: null,
      nowTime: 0,
      boxArr: [],
      imgArr: [],
      isMax: false,
      source: ''
    }
  },
  created () {
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
    this.noRate = localStorage.getItem(this.resId + 'isMax') || this.source === '3' || this.source === 'undefined' || this.source === '4'
    localStorage.removeItem(this.resId + 'isMax')
    // 视频最大化不显示,个人资源不显示, 其它非资源视频也不显示
  },
  mounted () {
    this.initTouchEvent()
    this.initOnlineFn()
  },
  methods: {
    initOnlineFn () {
      const arr = this.arr
      this.cacheOnlineImgFn(arr)
      setTimeout(() => {
        arr.forEach((v) => {
          const a = v.graphFilename.match(/(\d+)-(\d+)/g)
          const r = a[0].split('-')
          v.num = Number(r[0] + r[1])
        })
        arr.sort(this.compare('num'))
        arr.forEach((v) => {
          this.imgArr.push(v.graphPath)
        })
        this.boxArr = arr
        this.mainImg = this.imgArr[this.imgIndex]
        this.$nextTick(function () {
          setTimeout(() => {
            this.bscroll = new BScroll('.wrapper', {
              click: true,
              disableMouse: false,
              disableTouch: false,
              mouseWheel: {
                speed: 20,
                invert: false,
                easeTime: 300
              }
            })
            this.wheel()
            setTimeout(() => {
              this.bscroll.refresh()
              localStorage.removeItem(this.resId)
            }, 1000)
          }, 1000)
        })
      }, 300)
      setTimeout(() => {
        this.isLoad = true
      }, 3000)
    },
    compare (property) {
      return function (a, b) {
        const value1 = a[property]
        const value2 = b[property]
        return value1 - value2
      }
    },
    initTouchEvent () {
      // let box = document.getElementById('imgbox')
      // let img = document.getElementById('mainImg')
      const max = document.getElementById('image-max')
      const min = document.getElementById('image-min')
      // let initDistance = 0
      // let distance = 0
      // let keeping = 0
      const _this = this
      // box.addEventListener('touchstart', function (e) {
      //   if (e.touches.length >= 2) {
      //     // 双指触控时为放大缩小
      //     initDistance = Math.pow(e.touches[0].screenX - e.touches[1].screenX, 2) + Math.pow(e.touches[0].screenY - e.touches[1].screenY, 2)
      //       box.addEventListener('touchmove', function (e) {
      //           e.preventDefault()
      //           distance = Math.pow(e.touches[0].screenX - e.touches[1].screenX, 2) + Math.pow(e.touches[0].screenY - e.touches[1].screenY, 2)
      //           let initW = img.offsetWidth
      //           let initH = img.offsetHeight
      //           if (distance > initDistance + 100) {
      //               // _this.imgMax()
      //               img.style['max-width'] = 'none'
      //               img.style['max-height'] = 'none'
      //               img.style.width = (initW + 50) + 'px'
      //               img.style.height = (initH + 50 / initW * initH) + 'px'
      //               initDistance = distance
      //           } else if (distance < initDistance - 100 && initW > 800 && initH > 600) {
      //               // _this.imgMin()
      //               img.style.width = (initW - 50) + 'px'
      //               img.style.height = (initH - 50 / initW * initH) + 'px'
      //               initDistance = distance
      //           }
      //     }, false)
      //   } else {
      //     // 单指触控为拖动
      //     let disX = 0
      //     let disY = 0
      //     let timer = setInterval(function () {
      //       keeping++
      //     }, 20)
      //     box.addEventListener('touchend', function () {
      //       // keeping = 0
      //       clearInterval(timer)
      //     })
      //     if (keeping >= 10) {
      //       disX = e.touches[0].screenX - img.offsetLeft
      //       disY = e.touches[0].screenY - img.offsetTop
      //       img.addEventListener('touchmove', _e => {
      //         let left = _e.touches[0].screenX - disX
      //         let top = _e.touches[0].screenY - disY
      //         if (left < 0) img.style.left = '0'
      //         else if (left > box.offsetWidth) img.style.left = box.offsetWidth + 'px'
      //         else img.style.left = left + 'px'
      //         if (top < 0) img.style.top = '0'
      //         else if (top > box.offsetHeight) img.style.top = box.offsetHeight + 'px'
      //         else img.style.top = top + 'px'
      //       })
      //     }
      //   }
      // })
      // 长按缩放绑定事件
      max.addEventListener('touchstart', function () {
        _this.imgMax()
        max.addEventListener('touchend', function (e) {
          e.preventDefault()
          clearInterval(_this.longTimer)
        })
      })
      max.addEventListener('mousedown', function () {
        _this.imgMax()
        max.addEventListener('mouseup', function (e) {
          e.preventDefault()
          clearInterval(_this.longTimer)
          cancelAnimationFrame(_this.longTimer)
        })
      })
      min.addEventListener('touchstart', function () {
        _this.imgMin()
        min.addEventListener('touchend', function (e) {
          e.preventDefault()
          _this.boxUp()
        })
      })
      min.addEventListener('mousedown', function () {
        _this.imgMin()
        min.addEventListener('mouseup', function (e) {
          e.preventDefault()
          clearInterval(_this.longTimer)
        })
      })
    },
    imgMax () {
      // if (this.longTimer) return
      const _this = this
      const img = document.getElementById('mainImg')
      let initW = img.offsetWidth
      let initH = img.offsetHeight
      img.style.width = initW + 'px'
      img.style.height = initH + 'px'
      img.style['max-width'] = 'none'
      img.style['max-height'] = 'none'
      function maxFn () {
        img.style.width = (initW + 20) + 'px'
        img.style.height = (initH + 20 / initW * initH) + 'px'
        initW = img.offsetWidth
        initH = img.offsetHeight
        _this.longTimer = requestAnimationFrame(maxFn)
      }
      // clearInterval(this.longTimer)
      this.longTimer = requestAnimationFrame(maxFn)
      // this.longTimer = setTimeout(() => {
      // }, 20)
    },
    imgMin () {
      clearInterval(this.longTimer)
      const img = document.getElementById('mainImg')
      const img2 = document.getElementById('mainImg2')
      let initW = img.offsetWidth
      let initH = img.offsetHeight
      const initW2 = img2.offsetWidth
      const initH2 = img2.offsetHeight
      this.longTimer = setInterval(() => {
        if (initW > initW2 || initH > initH2) {
          img.style.width = (initW - 20) + 'px'
          img.style.height = (initH - 20 / initW * initH) + 'px'
          initW = img.offsetWidth
          initH = img.offsetHeight
        }
      }, 20)
    },
    boxUp () {
      clearInterval(this.longTimer)
    },
    wheel () {
      const dom = document.getElementById('mainImg')
      dom.onwheel = (e) => {
        const delta = (e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1))
        const dom = document.getElementById('mainImg')
        const dom2 = document.getElementById('mainImg2')
        const oWidth = dom.offsetWidth
        const oHeight = dom.offsetHeight
        const initW2 = dom2.offsetWidth
        const initH2 = dom2.offsetHeight
        dom.style['max-width'] = 'none'
        dom.style['max-height'] = 'none'
        if (delta > 0) {
          dom.style.width = (oWidth + 50) + 'px'
          dom.style.height = (oHeight + 50 / oWidth * oHeight) + 'px'
        } else if (delta < 0) {
          if (dom.offsetWidth > initW2 || dom.offsetHeight > initH2) {
            dom.style.width = oWidth - 50 + 'px'
            dom.style.height = oHeight - 50 / oWidth * oHeight + 'px'
          }
        }
      }
    },
    changeSlider () {
      // let dur = 100 / this.imgArr.length
      // let pos = Math.floor(this.imgIndex * dur)
      // if (this.imgIndex === 41) {
      //   pos = 100
      // }
      // if (this.imgIndex === 0) {
      //   pos = 0
      // }
      // this.$refs.slider.changePer(pos)
    },
    o2o () {
      const dom = document.getElementById('mainImg')
      dom.style.width = 'auto'
      dom.style.height = 'auto'
      dom.style.left = 'unset'
      dom.style.top = 'unset'
      dom.style['max-width'] = '100%'
      dom.style['max-height'] = '100%'
    },
    prev () {
      if (this.imgIndex > 0) {
        this.imgIndex--
      } else {
        this.imgIndex = this.imgArr.length - 1
      }
      this.changeSlider()
      this.mainImg = this.imgArr[this.imgIndex]
      this.o2o()
    },
    next () {
      if (this.imgIndex < this.imgArr.length - 1) {
        this.imgIndex++
      } else {
        this.imgIndex = 0
      }
      this.changeSlider()
      this.mainImg = this.imgArr[this.imgIndex]
      this.o2o()
    },
    durPer (per) {
      const dur = 100 / this.imgArr.length
      const pos = Math.floor(per / dur) >= this.imgArr.length ? this.imgArr.length - 1 : Math.floor(per / dur)
      this.mainImg = this.imgArr[pos]
      this.imgIndex = pos
    },
    chooseImg (item, idx) {
      this.imgIndex = idx
      this.changeSlider()
      this.mainImg = item
      this.o2o()
    },
    cacheOnlineImgFn (arr) {
      arr.forEach(val => {
        const img = new Image()
        img.src = val.graphPath
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/globals.scss";
.imgs-player{
  width: 100%;
  height: calc(100vh - 300px);
  .container{
    width: 100%;
    height: 100%;
    &.max{
      height: 100%;
    }
  }
}
.demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.demo-spin-col{
    height: 100%;
    position: absolute;
    display: flex;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border: 1px solid #eee;
}
.box{
    width: 100%;
    height:100%;
    box-sizing: border-box;
    display: flex;
    .left{
      flex:1;
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      .img-box{
        flex:1;
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        justify-content: center;
      }
      .img-name{
        display: flex;
        align-items: center;
        justify-content: center;
        // padding:20px;
        height: 30px;
        line-height: 30px;
        p{
          color: #333;
          font-size: 1.25rem;
        }
        div{
          color: #999;
          padding-left:20px;
          font-size: 1.25rem;
        }
      }
      .main-img{
        max-width: 100%;
        max-height: 100%;
        // width:100%;
        // height: calc(100% - 70px);
        // object-fit: scale-down;
        // top:50%;
        // left:50%;
        position: absolute;
        // transform: translate(-50%,-50%);
      }
      #mainImg2 {
        position: absolute;
      }
      .slider-box{
        // position: absolute;
        // bottom:50px;
        // left:50%;
        width: 80%;
        padding: 20px 0;
        // transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        div{
          cursor: pointer;
          width:40px;
          height: 40px;
          opacity: 1;
        }
        // &:hover{
        //   div{
        //     opacity: 1;
        //     transition: opacity ease 0.5s;
        //   }
        // }
        .o2o{
          margin:0 20px;
          background: url(~@/assets/o2o.png) no-repeat;
          background-size: 100% 100%;
          &:hover{
            background-image: url(~@/assets/o2o_on.png);
          }
        }
        .prev{
          margin:0 20px;
          background: url(~@/assets/prev.png) no-repeat;
          background-size: 100% 100%;
          &:hover{
            background-image: url(~@/assets/prev_on.png);
          }
        }
        .next{
          margin:0 20px;
          background: url(~@/assets/prev.png) no-repeat;
          background-size: 100% 100%;
          &:hover{
            background-image: url(~@/assets/prev_on.png);
          }
        }
        .max{
          margin:0 20px;
          background: url(~@/assets/max.png) no-repeat;
          background-size: 100% 100%;
          &:hover{
            background-image: url(~@/assets/max_on.png);
          }
        }
        .min{
          margin:0 20px;
          background: url(~@/assets/min.png) no-repeat;
          background-size: 100% 100%;
          &:hover{
            background-image: url(~@/assets/min_on.png);
          }
        }
        .turn{
          transform: rotateY(180deg);
        }
      }
      .slider{
        // position: absolute;
        // bottom:0;
        // left:50%;
        // width: 100%;
        // transform: translateX(-50%);
      }
    }
    .right{
      width:140px;
      overflow: hidden;
      height: calc(100% - 100px);
      padding:20px 0;
      .wrapper{
        height: 100%;
      }
    }
}
.img-mini{
  li{
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.on{
      box-shadow: 0 0 10px #f00;
    }
  }
  img{
    width:100px;
  }
}
.img-mask {
  z-index: 2500;
}
</style>
