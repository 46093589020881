// 基础课全章缓存数据
const cache = {
  namespaced: true,
  state: {
    isCacheDown: false, // 是否在缓存下载
    isDownload3D: false, // 是否是下载3D文件
    currentCacheChapterId: '', // 当前下载章节id
    cacheDownStep: 0, // 剩余下载数量
    downloadTotal: 0, // 当前下载总数
    cacheCourse: undefined,
    cacheResource: undefined,
    cacheResourceList: [],
    cacheParams: undefined,
    isCacheType: false, // 是否处于缓存模式
    onLine: true, // 是在线状态
    isCacheExport: false, // 是否下载导出
    isCloudCacheExport: false, // 是否云资源下载导出
    exportPath: '', // 导出地址
    exportId: '', // 导出资源id
    uploadArr: [], // 本地上传数组
    machineIden: '', // 用户机器Id
    userInfo: undefined, // 用户信息
    cacheChapter: [], // 用户缓存数据
    resId: '', // 下载3d资源id
    totalDownloadProgress: 0
  },
  mutations: {
    setIsCacheDown (state, flag) {
      state.isCacheDown = flag
    },
    setCurrentCacheChapterId (state, id) {
      state.currentCacheChapterId = id
    },
    setCacheDownStep (state, step) {
      state.cacheDownStep = step
    },
    setCacheDownTotal (state, step) {
      state.downloadTotal = step
    },
    setCacheCourse (state, cacheCourse) {
      state.cacheCourse = cacheCourse
    },
    setCacheResource (state, cacheResource) {
      state.cacheResource = cacheResource
    },
    setCacheResourceList (state, list) {
      state.cacheResourceList = list
    },
    setCacheParams (state, cacheParams) {
      state.cacheParams = cacheParams
    },
    setIsCacheType (state, isCacheType) {
      state.isCacheType = isCacheType
    },
    setOnLine (state, onLine) {
      state.onLine = onLine
    },
    setIsCacheExport (state, isCacheExport) {
      state.isCacheExport = isCacheExport
    },
    setIsCloudCacheExport (state, isCacheExport) {
      state.isCloudCacheExport = isCacheExport
    },
    setExportPath (state, exportPath) {
      state.exportPath = exportPath
    },
    setExportId (state, resId) {
      state.exportId = resId
    },
    setUploadArr (state, obj) {
      state.uploadArr.push(obj)
    },
    delUplaodArr (state, resId) {
      state.uploadArr = state.uploadArr.filter(val => val.resId !== resId)
    },
    setMachineIdenFn (state, data) {
      state.machineIden = data
    },
    setUserInfo (state, data) {
      state.userInfo = data
    },
    setCacheChapter (state, data) {
      state.cacheChapter = data
    },
    // 下载 3d 资源时的状态
    setDownload3DStatus (state, data) {
      state.resId = data.resId
      state.isDownload3D = data.isDownload3D
    },
    // 设置总的下载进度
    setTotalDownloadProgress (state, data) {
      state.totalDownloadProgress = data
    }
  },
  actions: {
    updateIsCacheDown ({ commit }, val) {
      commit('setIsCacheDown', val)
    },
    updateCurrentCacheChapterId ({ commit }, val) {
      commit('setCurrentCacheChapterId', val)
    },
    updateCacheDownStep ({ commit }, val) {
      commit('setCacheDownStep', val)
    }
  },
  getters: {
    getUploadArr: (state) => (chapterId, resType, list) => {
      let uploadArr = state.uploadArr
      uploadArr = uploadArr.filter(val => val.chapterId === chapterId && `${val.resType}` === `${resType}`)
      const obj = {}
      list.forEach(element => {
        obj[element.resId] = true
      })
      uploadArr = uploadArr.filter(val => !obj[val.resId])
      return uploadArr
    },
    getIsUpload: state => chapterId => !!state.uploadArr.filter(val => val.chapterId === chapterId).length,
    getUserInfo: state => state.userInfo,
    checkChapterIsCache: state => chapterId => {
      const res = state.cacheChapter.filter(val => (Number(val.chapterId) === Number(chapterId)))[0]
      return res && res.time
    }
  }
}

export default cache
