import axios from '@/common/request/request'
import { getUserId } from '@/storage/viewStorage'
// 编辑个人信息
export const editMyInfo = (params) => {
  return axios.request({
    method: 'post',
    url: 'system/myInfo/completeRegisterInfo',
    data: params
  })
}

// 获取用户信息
export const getInfo = userId => {
  return axios.request({
    method: 'get',
    url: 'system/myInfo/get/' + userId
  })
}

// 获取教师信息
export const getTeachInfo = userId => {
  return axios.request({
    method: 'get',
    url: 'system/teacher/mine?techId=' + userId
  })
}

// 获取用户下载次数信息
export const getUserDownloadInfoApi = userId => {
  if (!userId || userId === 'undefined') {
    userId = getUserId()
  }
  return axios.request({
    method: 'get',
    url: 'res/resdown/CountDownLog/' + userId || ''
  })
}

// 获取用户上传容量
export const getUserUploadInfoApi = userId => {
  return axios.request({
    method: 'get',
    url: 'system/space/' + getUserId()
  })
}

// 个人空间 查询分享的云资源|区域资源|个人资源
export function queryUserResources (searchType, userId) {
  return axios.request({
    url: `/res/userCourseResource/user/myResource/${searchType}`,
    method: 'GET',
    params: { userId }
  })
}

// 查询个人资源统计
export function getUserStatistics (userId) {
  return axios.request({
    url: '/res/resUserStatistics/user',
    method: 'GET',
    params: { userId }
  })
}

// 查询积分排名
export function getUserRanking (userId) {
  return axios.request({
    url: '/system/points/getUserPointsRanking',
    method: 'GET',
    params: { userId }
  })
}

// 获得用户权限
export const getUserAuthApi = data => {
  return axios.request({
    method: 'get',
    url: 'system/myInfo/getUcComboConfig',
    data
  })
}

// 根据token 获取用户权限
export const getInfoByToken = userId => {
  return axios.request({
    method: 'get',
    url: 'system/myInfo/get'
  })
}

// 获取专家信息
export const getExpertInfoApi = () => {
  return axios.request({
    method: 'get',
    url: 'system/myInfo/getMyExpertInfo'
  })
}
