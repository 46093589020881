import Mqtt from '@/common/libs/mqtt'
import config from '@/config'
const MqttStore = {
  namespaced: true,
  state: {
    client: null
  },
  mutations: {
    init (state) {
      if (state.client && state.client.connect) return
      state.client = new Mqtt(config.mqttUrl)
    },
    receiver (state, { topic, fn }) {
      if (!state.client) {
        console.error('未初始化')
        return
      }
      state.client.receiver(topic, fn)
    },
    unSubscribe (state, topic) {
      state.client.unSubscrib(topic)
    },
    close (state) {
      // console.log(23, state.client)
      state.client && state.client.close()
    }
  }
}

export default MqttStore
