import axios from '@/common/request/request'

// 字典
export const getDictType = (dictType) => {
  return axios.request({
    method: 'get',
    url: '/system/dict/data/type/' + dictType
  })
}

// 系统配置
export const getSysConfigApi = (dictType) => {
  return axios.request({
    method: 'get',
    url: '/system/config/configKey/' + dictType
  })
}
