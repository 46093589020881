import axios from '@/common/request/request'

// 编辑个人信息
export const addteacher = (params) => {
  return axios.request({
    method: 'post',
    url: 'system/teacher',
    data: params
  })
}

// 获取用户信息
export const editteacher = bizTeacher => {
  return axios.request({
    method: 'put',
    url: 'system/teacher',
    data: bizTeacher
  })
}

// 获取用户信息
export const getteacher = id => {
  return axios.request({
    method: 'get',
    url: 'system/teacher/' + id
  })
}

// 获取机构老师学校列表
export const getTeacherSchoolListApi = () => {
  return axios.request({
    method: 'get',
    url: '/system/myInfo/getMySchool'
  })
}

// 设置机构老师学校
export const postTeacherSchoolApi = (schlId) => {
  return axios.request({
    method: 'post',
    url: `/system/myInfo/selectSchool/${schlId}`
  })
}

// 教师认证
export function teacherCertify (data) {
  return axios.request({
    url: '/system/ucUserCertInfo',
    method: 'POST',
    data
  })
}

// 获取当前用户认证详细信息
export function queryCertInfo () {
  return axios.request({
    url: '/system/ucUserCertInfo/pc/getTeacherCertInfoByUser',
    method: 'GET'
  })
}
